import { BodyText, ButtonRect, HeaderL } from "../../GlobalStyles";
import { Container } from "./Checkout-Styles";
import PaymentMethods from "./PaymentMethods";
import Tip from "../Tips/Tip";
import HintIcon from "../../assets/svg/hint.svg?react";
import Coins from "../../assets/svg/coins.svg?react";
import { theme } from "../../utils/theme";
import { useCheckoutStore } from "./checkoutStore";
import OrderSummary from "./OrderSummary";
import TotalSummary from "./TotalSummary";
import { PAYMENT_METHODS } from "../../constants/constants";
import { useTicketCheckout } from "./hooks/useTicketCheckout";

const { colors } = theme;

export default function Checkout() {
  const { paymentMethod, hasEnoughWalletBalance, isMinting } =
    useCheckoutStore();
  const { buy } = useTicketCheckout();

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          width: "100%",
        }}
      >
        <HeaderL style={{ textTransform: "uppercase" }}>Checkout</HeaderL>
        <OrderSummary />
      </div>
      <PaymentMethods />
      <Tip
        icon={<HintIcon fill={colors.green} height={10} width={10} />}
        text="Credit card payments has additional fees."
      />
      {paymentMethod && <TotalSummary />}
      {paymentMethod === PAYMENT_METHODS.CREDIT_CARD && (
        <PaymentMethods isCards />
      )}
      <ButtonRect
        style={{ background: colors.green, border: "none" }}
        disabled={!paymentMethod || !hasEnoughWalletBalance || isMinting}
        onClick={buy}
      >
        <Coins stroke={colors.black} height={14.5} width={14.5} />
        <BodyText weight="600" color={colors.black}>
          {isMinting ? "Processing..." : "Pay"}
        </BodyText>
      </ButtonRect>
    </Container>
  );
}
