import { DEV_SEPOLIA_URL } from "../constants/constants";

export const getEventTickets = async ({ eventId }) => {
  if (!eventId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/events/${eventId}/tickets`
    );

    if (!response.ok) {
      throw new Error("Failed to get event tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getEventTicketsEstimate = async ({ eventId, artistId }) => {
  if (!eventId || !artistId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${DEV_SEPOLIA_URL}/onchain/events/tickets/estimate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ eventId, artistId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get event tickets estimate");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketABI = async () => {
  try {
    const response = await fetch(
      `https://cdn.momentify.xyz/ABI/Events/Tickets.sol/Tickets.json`
    );

    if (!response.ok) {
      throw new Error("Failed to get ticket ABI");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketManagerABI = async () => {
  try {
    const response = await fetch(
      `https://cdn.momentify.xyz/ABI/Events/TicketsManager.sol/TicketsManager.json`
    );

    if (!response.ok) {
      throw new Error("Failed to get ticket ABI");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketsMinted = async ({ owner, contractAddress, txn }) => {
  if (!owner || !contractAddress || !txn) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(`${DEV_SEPOLIA_URL}/onchain/mints`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ owner, contractAddress, txn }),
    });

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const insertTicketsMinted = async ({
  tickets,
  userId,
  txnHash,
  mints,
}) => {
  if (!tickets || !userId || !txnHash || !mints) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${DEV_SEPOLIA_URL}/onchain/events/tickets/saveTicketMints`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tickets, userId, txnHash, mints }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get event tickets estimate");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketsByUser = async ({ userId }) => {
  if (!userId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/tickets`
    );

    if (!response.ok) {
      throw new Error("Failed to get tickets by user");
    }

    const { data, success } = (await response.json()) || {};

    if (!success) {
      throw new Error("Failed to get tickets by user");
    }

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketDetails = async ({ userId, eventId, ticketType }) => {
  if (!userId) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/tickets/${eventId}/type/${ticketType}`
    );

    if (!response.ok) {
      throw new Error("Failed to get tickets by user");
    }

    const { data, success } = (await response.json()) || {};

    if (!success) {
      throw new Error("Failed to get tickets by user");
    }

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
