import { BodyText, ButtonRect, FooterText } from "../../../GlobalStyles";
import { Container, Content } from "./InviteCategory-Styles";
import MailPlus from "../../../assets/svg/mail-plus.svg?react";
import { theme } from "../../../utils/theme";
import { handleInviteFriend } from "../../../utils/utils";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useState } from "react";
import { useInviteLink, useSuccessfulInvites } from "../hooks/settingQueries";

export default function InviteCategory() {
  const { loggedInUser } = useAuth();
  const [inviteLink, setInviteLink] = useState(null);
  const inviteLinkQuery = useInviteLink({ userId: loggedInUser?.id }).data;
  const { data } = useSuccessfulInvites({
    inviteId: inviteLink?.id,
  });

  useEffect(() => {
    if (inviteLinkQuery) {
      setInviteLink(inviteLinkQuery);
    }
  }, [inviteLinkQuery]);

  return (
    <Container>
      <BodyText weight="600">Invite a friend</BodyText>
      <Content>
        <ButtonRect
          onClick={() => {
            handleInviteFriend(inviteLink, loggedInUser, setInviteLink);
          }}
        >
          <MailPlus height={16} width={16} stroke="white" />
          Invite a friend
          <BodyText color={theme.colors.green}>+100xp</BodyText>
        </ButtonRect>
        <FooterText secondary>
          <FooterText color={theme.colors.white}>{data || 0}</FooterText> fans
          invited!
        </FooterText>
      </Content>
    </Container>
  );
}
