import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Section } from "../../GlobalStyles";
import FanFollowers from "../../components/Followers/FanFollowers";
import { NavBarTypes } from "../../constants/constants";
import ErrorPage from "../Error";
import { useFollowerQuery } from "./hooks/followersQueries";
import TopNavV2 from "../../components/TopNavV2";

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export default function Followers() {
  const { id } = useParams();

  const { data: followers, error, isLoading } = useFollowerQuery({ id });

  if (error) return <ErrorPage />;

  return (
    <Container>
      <TopNavV2 title={NavBarTypes.FOLLOWERS} isSticky isBlurred />
      <FanFollowers fans={followers} isLoading={isLoading} />
    </Container>
  );
}
