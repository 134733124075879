import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@coinbase/onchainkit/styles.css";
import "./global.css";
import "./utils/sentry";
import ErrorBoundary from "./ErrorBoundary";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
