import { createModularAccountAlchemyClient } from "@alchemy/aa-alchemy";
import { WalletClientSigner } from "@alchemy/aa-core";
import { useContext, useMemo } from "react";
import { toast } from "react-toastify";
import {
  createWalletClient,
  custom,
  encodeFunctionData,
  parseGwei,
} from "viem";
import { base } from "viem/chains";
import { collectMoment } from "../api/activities";
import {
  getContractType,
  getTokenIdByTransaction,
  refreshMomentMetadata,
  setFanMomentNft,
  uploadFanMomentNftMetadata,
} from "../api/moment";
import {
  MINT_ERROR_MESSAGES,
  MOMENT_CLAIM_STATUS,
} from "../constants/constants";
import { AccountKitDispatchEvents } from "../constants/dispatchEvents";
import { AccountKitContext } from "../contexts/AccountKitContext";
import { momentNftAttributesGenerator } from "../utils/moment";
import { isProd } from "../utils/utils";
import useAuth from "./useAuth";
import useMomentPhotoUpload from "./useMomentPhotoUpload";
import { useWallets } from "@privy-io/react-auth";
import { usePrimaryWallet } from "./usePrimaryWallet";

const { PENDING, SUCCESS, FAILED } = MOMENT_CLAIM_STATUS;
const { PRIMARY_WALLET_NOT_CONNECTED, NO_PRIMARY_WALLET } = MINT_ERROR_MESSAGES;

export default function useAccountKit() {
  // Find the user's embedded wallet
  const { wallets, ready: walletsReady } = useWallets();
  const { loggedInUser } = useAuth();
  const { embedded_wallet_id: address } = loggedInUser || {};
  const { primaryWallet } = usePrimaryWallet();
  const { setMoment } = useMomentPhotoUpload();
  const { context, dispatch } = useContext(AccountKitContext);
  const {
    alchemyProvider,
    txnHash,
    claimStatus,
    error,
    currentPendingMessage,
    pendingMessages,
  } = context;

  const embeddedWallet = useMemo(
    () =>
      walletsReady &&
      wallets &&
      wallets.find((wallet) => wallet.walletClientType === "privy"),
    [wallets, address, walletsReady]
  );

  const setAlchemyProvider = async () => {
    if (!embeddedWallet || !loggedInUser) return;

    const baseHttp = "https://base-mainnet.g.alchemy.com/v2";

    try {
      const chain = {
        ...base,
        rpcUrls: {
          alchemy: {
            http: [baseHttp],
          },
        },
      };

      await embeddedWallet.switchChain(chain.id); // Switch the embedded wallet to the chain (Sepolia in this case

      // Get a viem client from the embedded wallet
      const eip1193provider = await embeddedWallet.getEthereumProvider();
      const privyClient = createWalletClient({
        account: embeddedWallet.address,
        chain: chain,
        transport: custom(eip1193provider),
      });

      // Create a smart account signer from the embedded wallet's viem client
      const privySigner = new WalletClientSigner(
        privyClient,
        "privy" // signerType
      );

      // Create an Alchemy Provider with the smart account signer
      const provider = await createModularAccountAlchemyClient({
        apiKey: import.meta.env.VITE_ALCHEMY_API_KEY,
        chain: chain,
        signer: privySigner, // or any SmartAccountSigner
        gasManagerConfig: {
          policyId: import.meta.env.VITE_ALCHEMY_GAS_POLICY_ID,
        },
      });

      provider._privy_client = privyClient;

      dispatch(AccountKitDispatchEvents.SET_ALCHEMY_PROVODER, provider);

      return provider;
    } catch (error) {
      console.error(error);

      dispatch(AccountKitDispatchEvents.SET_ALCHEMY_PROVODER, null);

      return null;
    }
  };

  const mintMomentOnChain = async (moment) => {
    if (!primaryWallet) {
      dispatch(AccountKitDispatchEvents.SET_ERROR, NO_PRIMARY_WALLET.DETAILS);
      dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
      return {
        success: false,
        error: NO_PRIMARY_WALLET.DETAILS,
      };
    }

    if (!primaryWallet?.connected) {
      dispatch(
        AccountKitDispatchEvents.SET_ERROR,
        PRIMARY_WALLET_NOT_CONNECTED.DETAILS
      );
      dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
      return {
        success: false,
        error: PRIMARY_WALLET_NOT_CONNECTED.DETAILS,
      };
    }

    dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, PENDING);
    dispatch(AccountKitDispatchEvents.SET_ERROR, null);

    let timeout, interval;
    let index = 0;
    timeout = setTimeout(() => {
      interval = setInterval(() => {
        dispatch(
          AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE,
          pendingMessages[index]
        );
        index = index < pendingMessages.length - 1 ? index + 1 : 0;
      }, 5000);
    }, 10000);

    if (moment?.transaction_reference) {
      dispatch(AccountKitDispatchEvents.SET_ERROR, "Moment already exists.");
      dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
      dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);
      clearTimeout(timeout);
      clearInterval(interval);

      return {
        success: false,
        error: "Moment already exists.",
      };
    }

    let provider = alchemyProvider;

    if (!provider || !loggedInUser || !moment) {
      dispatch(
        AccountKitDispatchEvents.SET_ERROR,
        "Missing required data to mint moment onchain, Try again later."
      );
      dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
      dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);
      clearTimeout(timeout);
      clearInterval(interval);

      return {
        success: false,
        error: "Missing required data to mint moment onchain, Try again later.",
      };
    }

    const contract = await getContractType();

    if (!contract) {
      dispatch(AccountKitDispatchEvents.SET_ERROR, "Contract not found");
      dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);
      return dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
    }

    const AlchemyTokenAbi = [
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "string",
            name: "passphrase",
            type: "string",
          },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    const uoCallData = encodeFunctionData({
      abi: AlchemyTokenAbi,
      functionName: "mint",
      args: [primaryWallet.address, import.meta.env.VITE_MOMENT_PASSPHRASE],
    });

    const amountToSend = parseGwei("0");

    // Wait for the user operation to be mined
    let userOperation;
    let txnHash;
    let uoHash;

    try {
      // temp override values
      const overrides = {
        maxPriorityFeePerGas: parseGwei("1"),
        callGasLimit: parseGwei("0.00025"),
        // maxFeePerGas: parseEther("100000000")
      };

      // Send the user operation
      userOperation = await provider.sendUserOperation({
        uo: {
          target: contract.contract_address, // The desired target contract address
          data: uoCallData, // The desired call data
          value: amountToSend,
        },
        overrides,
      });

      uoHash = userOperation.hash;

      // Wait for the user operation to be mined
      txnHash = await provider.waitForUserOperationTransaction({
        hash: userOperation.hash,
      });
    } catch (error) {
      console.error(error);

      await setFanMomentNft(loggedInUser.id, {
        userId: loggedInUser.id,
        user_name: loggedInUser.username,
        moment_id: moment.moment_id,
        event_id: moment.event_id,
        minted_at: new Date(),
        mint_status: FAILED,
        failure_reason: "User Operation failed",
        contract_id: contract.contract_id,
        artist_name: moment.performance_artist,
        artist_id: moment.performance_artist_id,
        uo_hash: uoHash,
      });

      dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
      dispatch(
        AccountKitDispatchEvents.SET_ERROR,
        error?.details ?? "Minting moment failed."
      );
      dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);

      clearTimeout(timeout);
      clearInterval(interval);

      return {
        success: false,
        error: error?.details ?? "Minting moment failed.",
      };
    }

    try {
      // Update the user operation status in the database and set the claim status to success once the transaction is mined
      if (txnHash) {
        let mintData = "";

        for (let i = 0; i < 5; i++) {
          if (i === 1) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          } else if (i > 1) {
            await new Promise((resolve) => setTimeout(resolve, 4000));
          }

          mintData = await getTokenIdByTransaction(
            primaryWallet.address,
            contract.contract_address,
            txnHash
          ).catch((error) => {
            console.error(error);
            return false;
          });

          if (mintData) break;
          else continue;
        }

        if (!mintData) {
          dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
          dispatch(AccountKitDispatchEvents.SET_ERROR, "Token ID not found");
          dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);

          clearTimeout(timeout);
          clearInterval(interval);

          return { success: false, error: "Token ID not found" };
        }

        const tokenId = mintData.tokenId;

        // Generate nft metadata attributes
        const nftMetaAttributes = momentNftAttributesGenerator(
          loggedInUser,
          moment
        );

        await uploadFanMomentNftMetadata(
          moment.moment_id,
          moment.user_id,
          tokenId,
          {
            image: `https://cdn.momentify.xyz/${isProd ? "moment" : "moment-staging"}/${tokenId}.png`,
            attributes: nftMetaAttributes,
          }
        ).catch((error) => {
          console.error(error);
          return false;
        });

        await refreshMomentMetadata(tokenId, contract.contract_address).catch(
          (error) => {
            console.error(error);
            return false;
          }
        );

        await setFanMomentNft(loggedInUser.id, {
          userId: loggedInUser.id,
          token_id: tokenId,
          user_name: loggedInUser.username,
          moment_id: moment.moment_id,
          event_id: moment.event_id,
          minted_at: new Date(),
          mint_status: SUCCESS,
          contract_id: contract.contract_id,
          artist_name: moment.performance_artist,
          user_image_upload: `https://cdn.momentify.xyz/${isProd ? "moment" : "moment-staging"}/${tokenId}.png`,
          metadata_upload: `https://cdn.momentify.xyz/${isProd ? "moment" : "moment-staging"}/${tokenId}`,
          artist_id: moment.performance_artist_id,
          transaction_reference: txnHash,
          uo_hash: uoHash,
        }).catch((error) => {
          console.error(error);
          return false;
        });

        setMoment({
          ...moment,
          txnHash,
        });

        dispatch(AccountKitDispatchEvents.SET_TXN_HASH, txnHash);
        dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, SUCCESS);
        dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);

        clearTimeout(timeout);
        clearInterval(interval);

        collectMoment(
          loggedInUser.id,
          moment.moment_id,
          moment.performance_artist_id
        )
          .then(({ success }) => {
            if (success) toast.success("Collected Moment + 25xp");
          })
          .catch((error) => {
            console.error(error);
          });
        return { success: true, txnHash };
      } else if (!txnHash) {
        await setFanMomentNft(loggedInUser.id, {
          userId: loggedInUser.id,
          user_name: loggedInUser.username,
          moment_id: moment.moment_id,
          event_id: moment.event_id,
          minted_at: new Date(),
          mint_status: FAILED,
          failure_reason: "No transaction hash found",
          contract_id: contract.contract_id,
          artist_name: moment.performance_artist,
          artist_id: moment.performance_artist_id,
          uo_hash: uoHash,
        });

        dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
        dispatch(
          AccountKitDispatchEvents.SET_ERROR,
          error?.details?.message ?? "Minting moment failed."
        );
        dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);

        clearTimeout(timeout);
        clearInterval(interval);

        return {
          success: false,
          error: error?.details?.message ?? "Minting moment failed.",
        };
      }
    } catch (error) {
      console.error(error);
      dispatch(AccountKitDispatchEvents.SET_CLAIM_STATUS, FAILED);
      dispatch(
        AccountKitDispatchEvents.SET_ERROR,
        error?.details?.message ?? "Minting moment failed."
      );
      dispatch(AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE, null);

      clearTimeout(timeout);
      clearInterval(interval);

      return {
        success: false,
        error: error?.details?.message ?? "Minting moment failed.",
      };
    }
  };

  return {
    alchemyProvider,
    setAlchemyProvider,
    mintMomentOnChain,
    currentPendingMessage,
    claimStatus,
    txnHash,
    error,
  };
}
