import { useCheckoutStore } from "../checkoutStore";
import {
  addDelayInMs,
  calculateTotal,
  countAndDeduplicateTickets,
  countTicketsByType,
} from "../../../utils/checkout";
import { useGetTicketABI } from "./checkoutQueries";
import { useMemo } from "react";
import usdcJson from "../../../constants/usdc-abi.json";
import { useChainId, useSwitchChain, useWriteContract } from "wagmi";
import { isProd } from "../../../utils/utils";
import {
  USDC_ADDRESS,
  USDC_ADDRESS_SEPOLIA,
} from "../../../constants/constants";
import { base, baseSepolia } from "viem/chains";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import useGlobalModal from "../../../hooks/useGlobalModal";
import PaymentSuccess from "../PaymentSuccess";
import { getTicketsMinted, insertTicketsMinted } from "../../../api/ticketing";
import useAuth from "../../../hooks/useAuth";

export const useTicketCheckout = () => {
  const { loggedInUser } = useAuth();
  const { ticketsSelected, contract } = useBuyTicketStore();
  const {
    ticketsTotal,
    momentifyFee,
    setIsMinting,
    setTxnHash,
    setBoughtTickets,
  } = useCheckoutStore();
  const { primaryWallet } = usePrimaryWallet();
  const { data: ticketABI } = useGetTicketABI();
  const { writeContractAsync } = useWriteContract();
  const chain = useChainId();
  const { switchChain } = useSwitchChain();
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();
  const { estimatedGasPrice, contractAddress } = contract || {};

  const USDC = isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA;
  const chainTobeUsed = isProd ? base : baseSepolia;

  const { parsedTotal } = useMemo(() => {
    if (!ticketsTotal) return { formattedTotal: "0", parsedTotal: 0 };

    const total = calculateTotal({
      ticketsTotal,
      momentifyFee,
      gas: estimatedGasPrice,
    });

    return total;
  }, [ticketsTotal, momentifyFee, estimatedGasPrice]);

  const buy = async () => {
    try {
      if (!primaryWallet) return;

      setIsMinting(true);

      const { GA, VIP } = countTicketsByType(ticketsSelected);

      if (!GA && !VIP) {
        setIsMinting(false);
        return;
      }      

      // Switch chain if not on the correct chain
      if (isProd && chain !== base.id) {
        switchChain({ chainId: base.id });
        await primaryWallet.switchChain(base.id);
      } else if (!isProd && chain !== baseSepolia.id) {
        switchChain({ chainId: baseSepolia.id });
        await primaryWallet.switchChain(baseSepolia.id);
      }

      console.log("Starting Approval...");

      const usdcApprovalTxn = await writeContractAsync({
        address: USDC,
        abi: usdcJson.abi,
        functionName: "approve",
        args: [contractAddress, parsedTotal],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!usdcApprovalTxn) {
        setIsMinting(false);
        console.warn("Failed to approve USDC");
        return;
      }

      console.log("USDC Approved");
      console.log("Starting Batch Mint...");

      const batchMintTxn = await writeContractAsync({
        address: contractAddress,
        abi: ticketABI,
        functionName: "batchMint",
        args: [primaryWallet.address, GA, VIP],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!batchMintTxn) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        return;
      }

      console.log("Batch Minted:", { batchMintTxn });

      setTxnHash(batchMintTxn);

      const boughtTickets = countAndDeduplicateTickets(ticketsSelected);
    
      setBoughtTickets(boughtTickets);
      
      console.log("Getting Tickets Minted...");

      const { data: batchMintData, success: getTicketsSuccess } = await getTicketsMinted({
        owner: primaryWallet.address,
        contractAddress: contractAddress,
        txn: batchMintTxn,
      }).catch((error) => {
        console.error(error);
        return false;
      });

      if (!getTicketsSuccess || !batchMintData || batchMintData?.length < 1) {
        setIsMinting(false);
        console.warn("Failed to get minted tickets");
        return;
      }

      console.log("Tickets Minted:", { batchMintData });
      console.log("Inserting Tickets Minted...");

      const { success, data } = await insertTicketsMinted({
        tickets: boughtTickets,
        userId: loggedInUser?.id,
        txnHash: batchMintTxn,
        mints: batchMintData || [],
      }) || {};

      if (!success || !data || data?.length < 1) {
        setIsMinting(false);
        console.warn("Failed to insert tickets minted");
        return;
      }

      toggleSheetModal();
      setSheetModalContent(<PaymentSuccess />);
      await addDelayInMs(500);
      toggleSheetModal();

      console.log("Tickets Inserted:", data);
      setIsMinting(false);

    } catch (error) {
      console.error(error);
      setIsMinting(false);
    } finally {
      setIsMinting(false);
    }
  };

  return { buy };
};
