import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 14px;
  gap: 12px;
  border-radius: 10px;
  background: none;
  align-items: center;
  justify-content: space-between;
  outline: 1px solid #ffffff26;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  &:focus + svg {
    filter: none; /* Ensure the icon does not get blurred */
  }
`;

export const SearchInput = styled.input`
  display: flex;
  border: none;
  background: transparent;
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  outline: none;

  &::placeholder {
    color: #8b8b96; /* Change this to your desired placeholder color */
  }
`;

export const ResultDropdown = styled.div`
  position: absolute;
  top: 35px; /* Position the dropdown below the input field */
  left: 0;
  width: 100%;
  background: #11111f;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 10;
  max-height: 140px;
  overflow-y: auto;
  border-radius: 0px 0px 10px 10px;
  outline: 1px solid #ffffff1f;
  box-sizing: border-box;
`;

export const ResultItem = styled.div`
  padding: 8px 16px;
  border-top: 1px solid #ffffff1f;
  /* cursor: pointer;
  &:hover {
    background: #f0f0f0;
  } */
`;
