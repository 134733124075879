import PropTypes from "prop-types";
import { FooterText } from "../../GlobalStyles";
import EventDetails from "./EventDetails";
import {
  Section,
  Container,
  TicketsContainer,
  TicketsHeader,
} from "./BuyTicket-Styles";
import { theme } from "../../utils/theme";
import Ticket from "./Ticket";
import Total from "./Total";
import { useBuyTicketStore } from "./buyTicketStore";

import { useCheckoutStore } from "../Checkout/checkoutStore";
import { useEffect } from "react";
import TopNavV2 from "../TopNavV2";

const { colors } = theme;

export default function BuyTicket({ event, ticketsData }) {
  const { tickets, contract } = ticketsData || {};
  const { perAccountMax } = contract || {};
  const {
    ticketsSelected,
    reset: resetBuyTicketStore,
    setEvent,
    setContract,
  } = useBuyTicketStore();
  const { reset: resetCheckoutStore } = useCheckoutStore();
  const isMaxQuantity = ticketsSelected.length >= perAccountMax;

  useEffect(() => {
    if (!event || !contract) return;

    setEvent(event);
    setContract(contract);
  }, [event, contract]);

  const handleBack = () => {
    resetBuyTicketStore();
    resetCheckoutStore();
  };

  return (
    <Section>
      <TopNavV2 title="Buy ticket" onBack={handleBack} isSticky isBlurred />
      <Container>
        <EventDetails event={event} />
        <TicketsContainer style={{ gap: "12px" }}>
          <TicketsHeader>
            <FooterText>Max of {perAccountMax} per account</FooterText>
            <FooterText color={isMaxQuantity ? colors.green : colors.grey}>
              {ticketsSelected.length}/{perAccountMax} selected
            </FooterText>
          </TicketsHeader>
          {tickets?.length > 0 && (
            <TicketsContainer style={{ gap: "16px" }}>
              {tickets.map((ticket) => (
                <Ticket
                  key={ticket.id}
                  ticket={ticket}
                  isMaxQuantity={isMaxQuantity}
                />
              ))}
            </TicketsContainer>
          )}
        </TicketsContainer>
      </Container>
      <Total />
    </Section>
  );
}

BuyTicket.propTypes = {
  event: PropTypes.object.isRequired,
  ticketsData: PropTypes.object.isRequired,
};
