import PropTypes from "prop-types";
import Moments from "../../assets/svg/moments.svg?react";
import Bookmark from "../../assets/svg/bookmark.svg?react";
import Tickets from "../../assets/svg/two-tickets.svg?react";
import Collectibles from "../../assets/svg/collectibles.svg?react";
import Tabs from "../Tabs";
import UserEvents from "./UserEvents";
import UserMoments from "./UserMoments";
import UserCollectibles from "./UserCollectibles";
import UserTickets from "./UserTickets";

export default function ProfileTabs({
  user = undefined,
  events,
  moments,
  collectibles,
  tickets,
  isMomentsLoading,
  isEventsLoading,
  isCollectiblesLoading,
  isTicketsLoading,
  defaultActiveTab,
}) {
  const tabs = [
    {
      id: "moments",
      icon: <Moments height="16px" />,
      component: (
        <UserMoments
          user={user}
          moments={moments}
          isMomentsLoading={isMomentsLoading}
        />
      ),
    },
    {
      id: "events",
      icon: <Bookmark height="16px" strokeWidth={1.5} />,
      component: (
        <UserEvents
          user={user}
          events={events}
          isEventsLoading={isEventsLoading}
        />
      ),
    },
    {
      id: "tickets",
      icon: <Tickets height="16px" strokeWidth={1.5} />,
      component: (
        <UserTickets tickets={tickets} isTicketsLoading={isTicketsLoading} />
      ),
    },
    {
      id: "collectibles",
      icon: <Collectibles height="16px" strokeWidth={1} />,
      component: (
        <UserCollectibles
          collectibles={collectibles}
          isCollectiblesLoading={isCollectiblesLoading}
        />
      ),
    },
  ];

  return (
    <Tabs tabs={tabs} defaultActiveTab={defaultActiveTab} type="profile" />
  );
}

ProfileTabs.propTypes = {
  user: PropTypes.object,
  events: PropTypes.array,
  moments: PropTypes.object,
  collectibles: PropTypes.array,
  tickets: PropTypes.array,
  defaultActiveTab: PropTypes.string,
  isMomentsLoading: PropTypes.bool,
  isEventsLoading: PropTypes.bool,
  isCollectiblesLoading: PropTypes.bool,
  isTicketsLoading: PropTypes.bool,
};
