import PropTypes from "prop-types";
import Bookmark from "../../../assets/svg/bookmark.svg?react";
import Polaroids from "../../../assets/svg/polaroids.svg?react";
import Collectibles from "../../../assets/svg/collectibles.svg?react";
import Tabs from "../../Tabs";
import EventsTab from "../../Tabs/EventsTab";
import MomentsTab from "../../Tabs/MomentsTab";
import CollectiblesTab from "../../Tabs/CollectiblesTab";

export default function VenueTabs({
  events,
  moments,
  collectibles,
  isEventsLoading,
  isMomentsLoading,
  isCollectiblesLoading,
}) {
  const tabs = [
    {
      id: "artists",
      icon: <Bookmark height="16px" stroke="white" />,
      component: <EventsTab events={events} isLoading={isEventsLoading} />,
    },
    {
      id: "photos",
      icon: <Polaroids height="16px" stroke="white" />,
      component: <MomentsTab moments={moments} isLoading={isMomentsLoading} />,
    },
    {
      id: "collectibles",
      icon: <Collectibles height="16px" stroke="white" />,
      component: (
        <CollectiblesTab
          collectibles={collectibles}
          isLoading={isCollectiblesLoading}
        />
      ),
    },
  ];

  return <Tabs tabs={tabs} defaultActiveTab="artists" type="artist" />;
}

VenueTabs.propTypes = {
  events: PropTypes.array,
  moments: PropTypes.array,
  collectibles: PropTypes.array,
  isEventsLoading: PropTypes.bool,
  isMomentsLoading: PropTypes.bool,
  isCollectiblesLoading: PropTypes.bool,
};
