import { useQuery } from "@tanstack/react-query";
import { getTicketDetails } from "../../api/ticketing";

export const useGetTickets = ({ userId, eventId, type }) => {
  return useQuery({
    queryKey: ["ticket", userId, eventId, type],
    queryFn: () => {
      return getTicketDetails({ userId, eventId, ticketType: type });
    },
    refetchInterval: 1000 * 60 * 4, // Refetch events every 1 min,
  });
};