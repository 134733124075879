import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CheckInEvent from "../../components/CheckIn/CheckInEvent";
import CheckInToast from "../../components/CheckIn/CheckInToast";
import EventArtists from "../../components/Event/EventArtists";
import EventHeader from "../../components/Event/EventHeader";
import EventInfo from "../../components/Event/EventInfo";
import EventTickets from "../../components/Event/EventTickets";
import withCheckInContextProvider from "../../hoc/withCheckInContextProvider";
import useAuth from "../../hooks/useAuth";
import useCheckIn from "../../hooks/useCheckIn";
import useCheckInModalToggle from "../../hooks/useCheckInModalToggle";
import useGeolocation from "../../hooks/useGeolocation";
import ErrorPage from "../Error";
import {
  EventContentContainer,
  EventSection,
  EventSkeleton,
} from "./Event-Styles";
import {
  useEventLoader,
  useGetEventTickets,
  useGetRoom,
} from "./hooks/eventQueries";
import PartialEmptyState from "../../components/PartialEmptyState";
import { BodyText, ButtonRectSm, FooterText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { REPORT_ENTITY_TYPES, REPORT_TYPES } from "../../constants/constants";
import { useUserStore } from "../Profile/store/userStore";
import BuyTicket from "../../components/BuyTicket";
import { useBuyTicketStore } from "../../components/BuyTicket/buyTicketStore";
import { isProd } from "../../utils/utils";
import TopNavV2 from "../../components/TopNavV2";
import RoomButton from "../../components/RoomButton";
import ShareButton from "../../components/ShareButton";
import Flag from "../../assets/svg/flag.svg?react";
import EventRoomInfo from "../../components/Event/EventRoomInfo";

/**
 * The Event component handles the logic for an event, including participant count, user interest, location, check-in, and event details display.
 * @returns {React.JSX.Element} The rendered Event component

 */
// We can use state here, store clicked event image and show it immediately
function Event() {
  const { id: eventId } = useParams();
  const [searchParams] = useSearchParams();
  const { isOpen: isBuyTicketsOpen } = useBuyTicketStore();
  const date = searchParams.get("date");
  const { loggedInUser, fetchSessionCookie } = useAuth();
  const {
    handleLocationPermission,
    currentLocation,
    resetLocation,
    isGeolocationPermitted,
    getCurrentPosition,
  } = useGeolocation();

  const eventLoaderQuery = useEventLoader({
    eventId: eventId,
    userId: loggedInUser?.id,
    date: date,
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
  });
  const event = eventLoaderQuery.data || {};
  const {
    id,
    headline_artist,
    headline_artist_id,
    event_name,
    city,
    date: eventDate,
    artists,
    image,
    room: eventRoom,
  } = event || {};

  const navigate = useNavigate();

  const [participantsCount, setParticipantsCount] = useState(0);
  const { userParticipated } = useUserStore();

  const [interested, setInterested] = useState(
    userParticipated.includes(parseInt(eventId))
  );
  const [room, setRoom] = useState(null);

  const { handleToggle, isOpen } = useCheckInModalToggle();

  const {
    currentEvent,
    setCurrentEvent,
    handleCheckInCurrentEvent,
    hasMomentExisted,
  } = useCheckIn();

  const roomQuery = useGetRoom({
    room: eventRoom ?? null,
  });

  const eventTicketsQuery = useGetEventTickets({
    artistId: headline_artist_id,
    eventId: eventId,
  });

  // Move this to custom hook. Move current position to state management
  // Use effect for getting current postion
  useEffect(() => {
    if (isGeolocationPermitted) {
      // Get current location every 10 seconds
      const intervalId = setInterval(() => {
        getCurrentPosition();
        // queryClient.refetchQueries({
        //   queryKey: ["one_event"],
        //   type: "active",
        // });
      }, 1000);

      // Clear interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [getCurrentPosition, isGeolocationPermitted]);

  useEffect(() => {
    if (!eventLoaderQuery.isLoading) {
      setParticipantsCount(event?.interested_count ?? 0);
      setInterested(userParticipated.includes(parseInt(eventId)));
      setParticipantsCount(event?.interested_count);
    }
  }, [event, eventLoaderQuery.isLoading, userParticipated, eventId]);

  // Redundant pieces of code? Location should be stored in session
  // Get location and session on load
  useEffect(() => {
    if (!event || eventLoaderQuery?.isLoading) return;

    // handleRoomLoad();
    handleLocationPermission();
    fetchSessionCookie();

    if (isOpen) return;
    setCurrentEvent(event ?? null);

    // Reset location when unmounting
    return () => {
      resetLocation();
      setCurrentEvent(null);
    };
  }, [eventLoaderQuery.data, eventLoaderQuery.isLoading]);

  useEffect(() => {
    if (roomQuery.isLoading || roomQuery?.data?.length === 0) return;
    setRoom(roomQuery?.data ?? null);
  }, [roomQuery.data, roomQuery.isLoading]);

  const handleParticipantCount = async () => {
    if (interested) {
      setParticipantsCount((prevCount) => prevCount - 1);
      return;
    }

    setParticipantsCount((prevCount) => prevCount + 1);
  };

  const handleOpenCheckInModal = () => {
    if (!navigator.geolocation) return;

    handleToggle();
    handleCheckInCurrentEvent();
  };

  if (eventLoaderQuery.isLoading) return <EventSkeleton />;

  if (
    !eventLoaderQuery?.data ||
    eventLoaderQuery?.data?.length == 0 ||
    eventLoaderQuery.error
  )
    return <ErrorPage description="We can't find this event." />;

  if (eventLoaderQuery.data && isOpen && !isBuyTicketsOpen) {
    return (
      <CheckInEvent
        onClose={handleToggle}
        onRefresh={handleCheckInCurrentEvent}
      />
    );
  }

  if (event && isBuyTicketsOpen && eventTicketsQuery?.data && !isProd) {
    return <BuyTicket event={event} ticketsData={eventTicketsQuery?.data} />;
  }

  return (
    <EventSection>
      <TopNavV2
        title={headline_artist ?? event_name}
        icon={image}
        content={
          <>
            <RoomButton event={event} dark />
            <ShareButton
              url={window.location.href}
              title={event_name ?? headline_artist}
              text={`Check out this event - ${headline_artist} - ${city} - ${moment(eventDate).format("DD/MM/YY")}`}
              noPadding
            />
          </>
        }
      />
      <EventHeader
        event={event}
        room={room ?? null}
        interested={interested}
        participantsCount={participantsCount}
        handleParticipantCount={handleParticipantCount}
      />
      <EventContentContainer>
        {/* Event Main Info */}
        <EventInfo event={event} />
        <EventRoomInfo event={event} participants={participantsCount} />
        {/* Event Artists */}
        <EventArtists
          artists={artists}
          headline_artist_id={headline_artist_id}
          isPostEvent={
            moment(eventDate).format("DD") == moment().format("DD") &&
            moment().format("HH") > 23
          }
        />
        {/* Tickets */}
        <EventTickets event={event} artistTickets={eventTicketsQuery?.data} />
      </EventContentContainer>
      <CheckInToast
        event={currentEvent}
        onClick={handleOpenCheckInModal}
        hasMomentExisted={hasMomentExisted}
      />
      <PartialEmptyState
        description={
          <BodyText color={theme.colors.grey}>
            Did we miss some info? Earn{" "}
            <BodyText color={theme.colors.green}>10xp</BodyText>!
          </BodyText>
        }
        button={
          <ButtonRectSm
            onClick={() =>
              navigate(
                `/report?type=${REPORT_TYPES.EVENT}&entity_type=${REPORT_ENTITY_TYPES.EVENT_ENTITY}&entity_id=${id}`
              )
            }
            style={{ marginBottom: 12 }}
          >
            <Flag stroke={theme.colors.white} />
            <FooterText weight="600">Let us know</FooterText>
          </ButtonRectSm>
        }
        style={{ paddingBottom: "12px" }}
      />
    </EventSection>
  );
}

export default withCheckInContextProvider(Event);
