import { create } from "zustand";

export const useCheckoutStore = create((set) => ({
  event: null,
  setEvent: (event) => set({ event }),

  paymentMethod: null,
  setPaymentMethod: (method) => set({ paymentMethod: method }),

  cardPaymentMethod: null,
  setCardPaymentMethod: (method) => set({ cardPaymentMethod: method }),

  hasEnoughWalletBalance: true,
  setHasEnoughWalletBalance: (hasEnough) =>
    set({ hasEnoughWalletBalance: hasEnough }),

  momentifyFee: 0,
  handleMomentifyFee: (fee) => {
    set((state) => ({ momentifyFee: state.momentifyFee + fee }));
  },

  ticketsTotal: 0,
  handleTicketsTotal: (price) => {
    set((state) => ({ ticketsTotal: state.ticketsTotal + price }));
  },

  total: 0,
  handleTotal: (price) => {
    set((state) => ({ total: state.total + price }));
  },

  isMinting: false,
  setIsMinting: (isMinting) => set({ isMinting }),

  txnHash: null,
  setTxnHash: (hash) => set({ txnHash: hash }),

  boughtTickets: [],
  setBoughtTickets: (tickets) => set({ boughtTickets: tickets }),

  error: null,
  setError: (err) => set({ error: err }),

  reset: () =>
    set({
      event: null,
      paymentMethod: null,
      cardPaymentMethod: null,
      total: 0,
      ticketsTotal: 0,
      momentifyFee: 0,
      txnHash: null,
      isMinting: false,
      boughtTickets: [],
      error: null,
    }),
}));
