import { useState } from "react";
import PropTypes from "prop-types";
import { theme } from "../../utils/theme";
import { BodyText } from "../../GlobalStyles";
import { useScrollStore } from "../../store/scrollStore";
import {
  Container,
  Tab,
  TabComponentContainer,
  TabContainer,
} from "./Tabs-Styles";

export default function Tabs({ tabs, defaultActiveTab, type }) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { scrollPosition } = useScrollStore();
  const blurIsActive =
    type === "profile" ||
    type === "following" ||
    type === "followers" ||
    type === "performance";

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <Container>
      <TabContainer blur={blurIsActive || scrollPosition > 200}>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            active={activeTab === tab.id ? true : false}
            type={type}
          >
            {tab.icon}
            {tab.label && (
              <BodyText
                color={activeTab === tab.id ? "white" : theme.colors.grey}
              >
                {tab.label}
              </BodyText>
            )}
          </Tab>
        ))}
      </TabContainer>
      {tabs.map((tab) => (
        <TabComponentContainer
          key={tab.id}
          active={activeTab === tab.id ? true : false}
        >
          {tab.component}
        </TabComponentContainer>
      ))}
    </Container>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      component: PropTypes.element,
    })
  ).isRequired,
  defaultActiveTab: PropTypes.string,
  type: PropTypes.string,
};
