import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  min-height: 100svh;
  max-height: 100svh;
  width: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundOverlay};
  background-color: ${({ theme }) => theme.colors.background};
  background-blend-mode: multiply;
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  padding: 0 20px 16px;
`;

export const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TicketsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
