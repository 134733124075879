import PropTypes from "prop-types";
import Moments from "../../../assets/svg/moments.svg?react";
import Collectibles from "../../../assets/svg/collectibles.svg?react";
import Tabs from "../../Tabs";
import MomentsTab from "../../Tabs/MomentsTab";
import CollectiblesTab from "../../Tabs/CollectiblesTab";
import { theme } from "../../../utils/theme";

export default function PerformanceTabs({
  moments,
  collectibles,
  isMomentsLoading,
  isCollectiblesLoading,
}) {
  const tabs = [
    {
      id: "moments",
      icon: <Moments height="16px" stroke={theme.colors.white} />,
      component: <MomentsTab moments={moments} isLoading={isMomentsLoading} />,
    },
    {
      id: "collectibles",
      icon: <Collectibles stroke={theme.colors.white} />,
      component: (
        <CollectiblesTab
          collectibles={collectibles}
          isLoading={isCollectiblesLoading}
        />
      ),
    },
  ];

  return <Tabs tabs={tabs} defaultActiveTab="moments" type="performance" />;
}

PerformanceTabs.propTypes = {
  moments: PropTypes.array,
  collectibles: PropTypes.array,
  isMomentsLoading: PropTypes.bool,
  isCollectiblesLoading: PropTypes.bool,
};
