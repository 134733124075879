import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { rewardEXP } from "../../../../api/activities";
import { useUserStore } from "../../../../routes/Profile/store/userStore";
import { updateUserXPStatus } from "../../../../api/user";

export const useRewardXP = () => {
  const queryClient = useQueryClient();
  const { setExternalMints, externalMints } = useUserStore();

  return useMutation({
    mutationKey: ["reward-xp"],
    mutationFn: async ({
      loggedInUserId,
      action_type,
      artist_ids = null,
      xp,
    }) => {
      await rewardEXP(loggedInUserId, action_type, artist_ids, xp);
    },
    onSuccess: (_, { xp, action_type }) => {
      toast.dismiss();
      toast.success(`+ ${xp}xp`);

      const updatedExternalMints = externalMints.map((mint) =>
        mint.transaction_hash === action_type
          ? { ...mint, is_rewarded: true }
          : mint
      );
      setExternalMints(updatedExternalMints);

      queryClient.refetchQueries({
        queryKey: ["external-mints"],
      });
      queryClient.refetchQueries({
        queryKey: ["stats"],
      });
    },
    onError: (error) => {
      toast.error("An error occurred. Please try again later." + error);
    },
  });
};

export const useUpdateUserXPStatus = () => {
  const { setUnrewardedGuessXP } = useUserStore();

  return useMutation({
    mutationKey: ["update-user-xp-status"],
    mutationFn: async ({ fid }) => {
      await updateUserXPStatus(fid);
    },
    onSuccess: () => {
      setUnrewardedGuessXP([]);

      // queryClient.refetchQueries({
      //   queryKey: ["unrewarded_guess_xp"],
      // });
    },
    onError: (error) => {
      toast.error("An error occurred. Please try again later." + error);
    },
  });
};
