import PropTypes from "prop-types";
import ArtistItem from "../../Artist/ArtistItem";
import { BodyText, FooterText } from "../../../GlobalStyles";
import { useEffect, useState } from "react";
import {
  ArtistsContainer,
  Container,
  ExtraSupportingCount,
  SeeAllArtists,
  SupportingArtistImages,
  SupportingArtistNames,
  SupportingArtists,
} from "./EventArtists-Styles";
import { ArtistImage } from "../../Events/YourEvent/YourEvent-Style";
import { ArtistPlaceholder } from "../../../constants/constants";

export default function EventArtists({
  artists,
  headline_artist_id,
  isPostEvent,
}) {
  const [showAllArtists, setShowAllArtists] = useState(false);

  useEffect(() => {
    if (!artists || !headline_artist_id || artists?.length < 2) return;

    // Make the headline artist the first one in the list based

    const index = artists.findIndex(
      (artist) => artist.id === headline_artist_id
    );
    if (index !== -1) {
      const [headlineArtist] = artists.splice(index, 1);
      artists.unshift(headlineArtist);
    }
  }, [artists, headline_artist_id]);

  const handleSeeAllArtists = () => {
    setShowAllArtists(!showAllArtists);
  };

  if (artists.length === 0 || !artists) return null;

  return (
    <Container>
      <BodyText weight="600">Line up</BodyText>
      {!showAllArtists && artists.length > 1 ? (
        <SupportingArtists onClick={handleSeeAllArtists}>
          <SupportingArtistImages>
            {artists.map(
              (artist, index) =>
                index < 3 && (
                  <ArtistImage
                    style={{
                      width: 40,
                      height: 40,
                      translate: index > 0 && "-12px 0px",
                    }}
                    key={artist.id}
                    src={artist?.profile_img ?? ArtistPlaceholder}
                    onError={(e) => (e.target.src = ArtistPlaceholder)}
                  />
                )
            )}
            {artists.length > 3 && (
              <ExtraSupportingCount
                style={{
                  translate: "-12px 0px",
                }}
              >
                <FooterText>{artists.length - 3}</FooterText>
              </ExtraSupportingCount>
            )}
          </SupportingArtistImages>
          <SupportingArtistNames>
            {artists.map(
              (artist, index) =>
                index < 3 && (
                  <FooterText key={artist?.id}>
                    {artist?.artist_name}
                    {index < artists.length - 1 && artists.length > 1 && ", "}
                    {index + 1 === artists.length - 1 &&
                      artists.length > 3 &&
                      " and more"}
                  </FooterText>
                )
            )}
          </SupportingArtistNames>
        </SupportingArtists>
      ) : (
        <ArtistsContainer>
          {artists.length > 0 &&
            artists.map((artist, index) => (
              <ArtistItem
                key={index}
                artist={artist}
                isPostEvent={isPostEvent}
              />
            ))}
        </ArtistsContainer>
      )}
      {/* Render 'See more' or 'Show less' button based on the number of events */}
      {artists.length > 1 && showAllArtists && (
        <SeeAllArtists onClick={handleSeeAllArtists}>
          {showAllArtists ? "Show less" : "Show all"}
        </SeeAllArtists>
      )}
    </Container>
  );
}

EventArtists.propTypes = {
  artists: PropTypes.array,
  headline_artist_id: PropTypes.number,
  isPostEvent: PropTypes.bool,
};
