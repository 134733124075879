import PropTypes from "prop-types";
import { ButtonsContainer, Container, Header } from "./WalletCard-Styles";
import {
  BodyText,
  ButtonRectSm,
  ButtonRectXs,
  FooterText,
  FooterTextSm,
} from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import MomIcon from "../../../assets/svg/momentify.svg?react";
import WalletCardBalance from "../WalletCardBalance";
import WalletCardFooter from "../WalletCardFooter";
import { usePrivy } from "@privy-io/react-auth";
import useAuth from "../../../hooks/useAuth";

const { colors } = theme;

export default function WalletCard({ wallet, primary }) {
  const { unlinkWallet } = usePrivy();
  const { updateCurrentUser } = useAuth();

  const { walletClientType, connected, address } = wallet || {};
  const isPrivy = walletClientType === "privy";

  const setPrimaryWallet = async () => {
    await updateCurrentUser({
      embedded_wallet_id: address,
    });
  };

  const unlinkAndDisconnectWallet = () => {
    unlinkWallet(address);
    connected && wallet?.disconnect();
  };

  return (
    <Container primary={primary}>
      <Header>
        <BodyText color={colors.grey} weight="600">
          Balance
        </BodyText>
        {primary ? (
          <MomIcon height={14} width={23.19} fill="white" />
        ) : (
          <ButtonsContainer style={{ gap: "16px" }}>
            {!isPrivy && (
              <ButtonRectXs
                style={{ background: colors.greyBtn, border: "none" }}
                onClick={unlinkAndDisconnectWallet}
              >
                <FooterTextSm weight="600">Unlink</FooterTextSm>
              </ButtonRectXs>
            )}
            <ButtonRectXs onClick={setPrimaryWallet} disabled={!connected}>
              <FooterTextSm weight="600">Make primary</FooterTextSm>
            </ButtonRectXs>
          </ButtonsContainer>
        )}
      </Header>
      <WalletCardBalance wallet={wallet} />
      {primary && (
        <ButtonsContainer>
          <ButtonRectSm disabled style={{ opacity: 0.4 }}>
            <FooterText weight="600">Withdraw</FooterText>
          </ButtonRectSm>
          <ButtonRectSm
            background={colors.greyBtn}
            style={{
              border: "none",
            }}
            onClick={() => wallet?.fund()}
          >
            <FooterText weight="600">Deposit</FooterText>
          </ButtonRectSm>
        </ButtonsContainer>
      )}
      <WalletCardFooter wallet={wallet} />
    </Container>
  );
}

WalletCard.propTypes = {
  wallet: PropTypes.object,
  primary: PropTypes.bool,
};
