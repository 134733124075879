import AnimatedDropdown from "../../Dropdown/AnimatedDropdown";
import { theme } from "../../../utils/theme";
import { Content, Header, Item } from "./TotalSummary-Styles";
import { BodyText, HeaderL, HeaderSm } from "../../../GlobalStyles";
import { formatPrice } from "../../../utils/utils";
import { useCheckoutStore } from "../checkoutStore";
import { PAYMENT_METHODS } from "../../../constants/constants";

const { colors } = theme;

export default function TotalSummary() {
  const { paymentMethod, total, ticketsTotal, momentifyFee } = useCheckoutStore();

  const formattedTotal = formatPrice(total);
  const formattedTotalTickets = formatPrice(ticketsTotal + momentifyFee, false);
  return (
    <AnimatedDropdown
      header={
        <Header>
          <BodyText>Purchase total</BodyText>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <HeaderL lineHeight="1">${formattedTotal[0]}</HeaderL>
            <HeaderSm>.{formattedTotal[1]}</HeaderSm>
          </div>
        </Header>
      }
      content={
        <Content>
          <Item>
            <BodyText secondary>Tickets + fees</BodyText>
            <BodyText>${formattedTotalTickets}</BodyText>
          </Item>
          {paymentMethod === PAYMENT_METHODS.CREDIT_CARD && (
            <Item>
              <BodyText secondary>Card processing fee est.</BodyText>
              <BodyText>$1.00</BodyText>
            </Item>
          )}
        </Content>
      }
      bg={colors.glass}
      isHeaderCenter
    />
  );
}
