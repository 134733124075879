import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { getBadgeQualifications } from "../api/badges";
import {
  checkInToEvent,
  checkUserInterestByUserId,
  getCheckInLatestHistory,
  getEligibility,
  getEventPerformances,
  getUserDuplicateCheckins,
} from "../api/events";
import { addPerformanceMoment, getUserMomentByEvent } from "../api/moment";
import { getUserXp, setUserAction } from "../api/profile";
import BadgeAttainment from "../components/Badges/BadgeAttainment";
import { NearbyMeters } from "../constants/constants";
import { CheckInDispatchEvents } from "../constants/dispatchEvents";
import { CheckInContext } from "../contexts/CheckInContext";
import { CheckInOutcome } from "../enums/check-in";
import { isCinnyAuthenticated, isCinnySupported } from "../utils/cinny-utils";
import { createOrUpdatePostEventRoomHandler } from "../utils/matrix-utils";
import { getDistanceFromLatLong, setDelay } from "../utils/utils";
import useAuth from "./useAuth";
import useCheckInModalToggle from "./useCheckInModalToggle";
import useGeolocation from "./useGeolocation";
import useGlobalModal from "./useGlobalModal";

function useCheckIn() {
  const { loggedInUser, sessionId } = useAuth();
  const { options } = useGeolocation();
  const { context, dispatch } = useContext(CheckInContext);
  const { setCenterModalContent, toggleCenterModal } = useGlobalModal();
  const queryClient = useQueryClient();
  const { isOpen } = useCheckInModalToggle();

  const {
    currentEvent = undefined,
    currentCheckIn,
    momentsCollected,
    isEligible,
    isNearby,
    isLoading,
    isTooFar,
    isCheckedIn,
    hasMomentExisted,
    successfullyCheckedIn,
    successfullyClaimedMoment,
    successfullyEarnedXp,
    earnedXp,
    momentNeedsLogin,
    error,
    AiImage,
    selectingMoments,
    selectedPerformances,
    performances,
  } = context ?? {};

  const getEligibilityStatus = async (event) => {
    const eligibility = await getEligibility(event);

    dispatch(CheckInDispatchEvents.SET_CURRENT_EVENT, event);
    dispatch(CheckInDispatchEvents.SET_ELIGIBLE, eligibility);

    return eligibility;
  };

  const getIsEventNearby = async (event) => {
    if (event.distance_meters < NearbyMeters)
      dispatch(CheckInDispatchEvents.SET_NEARBY, true);
    else dispatch(CheckInDispatchEvents.SET_NEARBY, false);
  };

  const setCurrentEvent = async (event) => {
    if (!event || isOpen) return;

    dispatch(CheckInDispatchEvents.SET_RESET);
    dispatch(CheckInDispatchEvents.SET_LOADING, true);

    const isEligible = await getEligibilityStatus(event);
    if (isEligible) {
      // Check here if checked in
      await getUserHasMomentAlready(event);
    }
    await getIsEventNearby(event);

    dispatch(CheckInDispatchEvents.SET_LOADING, false);
  };

  const getUserHasMomentAlready = async (event) => {
    if (loggedInUser) {
      const { data: moment } = await getUserMomentByEvent({
        eventId: event.event_id ?? event.id,
        userId: loggedInUser?.id,
      }).catch(() => {
        return dispatch(
          CheckInDispatchEvents.SET_ERRORS,
          "There seems to be an error happening when checking in, apologies. Try again later."
        );
      });
      if (moment) {
        dispatch(CheckInDispatchEvents.SET_HAS_MOMENT_EXISTED, moment);
        return true;
      }
    }

    return false;
  };

  const IsEventStillNearby = async (event, currentLocation) => {
    const distance = Math.max(
      getDistanceFromLatLong(
        currentLocation.coords.latitude,
        currentLocation.coords.longitude,
        event.latitude ?? event.geometry.coordinates[1],
        event.longitude ?? event.geometry.coordinates[0]
      ) - 100,
      0
    );

    if (distance < 50) return true;

    return false;
  };

  const getCheckInDuplicate = async (event, currentLocation) => {
    const duplicate = await getUserDuplicateCheckins(
      sessionId,
      event,
      loggedInUser
    );

    if (!duplicate) return false;

    await checkInToEvent({
      sessionId: sessionId,
      user: {
        id: loggedInUser?.id,
        walletAddress: loggedInUser?.embedded_wallet_id,
      },
      deviceInfo: "",
      browserInfo: "",
      location: currentLocation,
      checkinOutcome: CheckInOutcome.FAILED,
      failedReason: "User has already checked in to this event.",
      event: event,
    });

    return true;
  };

  const showBadgesAttained = (badges) => {
    setCenterModalContent(<BadgeAttainment badges={badges} />);
    toggleCenterModal();
  };

  const handleBadgesAttained = async () => {
    const badgeQualifications = await getBadgeQualifications(loggedInUser?.id);

    if (badgeQualifications?.length === 0) return;

    await setDelay(2000);

    showBadgesAttained(badgeQualifications);

    await setDelay(1000);
  };

  const handleMomentAdd = async (
    checkInId,
    eventId,
    performanceArtist,
    performanceArtistId,
    performanceId,
    officialCheckinNumber,
    artistIds
  ) => {
    const moment = await addPerformanceMoment({
      checkInId,
      eventId,
      performanceArtist,
      performanceArtistId,
      performanceId,
      userId: loggedInUser.id,
      imageUrl: AiImage,
      officialCheckinNumber,
      artistIds,
    });

    if (!moment?.success || !moment.data)
      return dispatch(
        CheckInDispatchEvents.SET_ERRORS,
        "There seems to be an error happening when claiming your moment, apologies. Try again later."
      );

    return moment;
  };

  const handlePostEventRoomCreation = async (moment) => {
    if (isCinnySupported() && isCinnyAuthenticated() && moment) {
      await createOrUpdatePostEventRoomHandler(moment);
    }
  };

  const setMomentNeedsLogin = async () => {
    dispatch(CheckInDispatchEvents.SET_MOMENT_NEEDS_LOGIN);
    dispatch(CheckInDispatchEvents.SET_SELECTING_MOMENTS, false);
  };

  const setSelectedPerformances = async (performances) => {
    dispatch(CheckInDispatchEvents.SET_SELECTED_PERFORMANCES, performances);
  };

  const setMomentsCollected = async (moments) => {
    dispatch(CheckInDispatchEvents.SET_MOMENTS_COLLECTED, moments);
  };

  const setSuccessfullyClaimedMoment = async () => {
    dispatch(CheckInDispatchEvents.SET_SUCCESSFULLY_CLAIMED_MOMENT);
  };

  const setSuccessfullyEarnedXp = async () => {
    dispatch(CheckInDispatchEvents.SET_SUCCESSFULLY_EARNED_XP);
  };

  const setEarnedXp = async (earnedXp, newTotalXp) => {
    dispatch(CheckInDispatchEvents.SET_EARNED_XP, {
      earnedXp,
      newTotalXp,
    });
  };

  const handleCheckInCurrentEvent = async () => {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition(
      async (currentLocation) => {
        try {
          dispatch(CheckInDispatchEvents.SET_RESET_EXCEPT_CURRENT_EVENT);
          dispatch(CheckInDispatchEvents.SET_LOADING, true);

          await setDelay(2000);

          // // Validates if user has moment to the current event already
          const hasMoment = await getUserHasMomentAlready(currentEvent);

          if (hasMoment) return;

          // If user is logged out, validate if logged out user has duplicate check in
          if (!loggedInUser) {
            const duplicate = await getCheckInDuplicate(
              currentEvent,
              currentLocation
            );

            if (duplicate)
              return dispatch(CheckInDispatchEvents.SET_CHECK_IN_ALREADY);
          }

          if (!currentEvent) {
            dispatch(
              CheckInDispatchEvents.SET_ERRORS,
              "There seems to be an error happening when checking in, apologies. Try again later."
            );
            return;
          }

          // Get the eligibility status of the event if user moved places
          const eligibility = await IsEventStillNearby(
            currentEvent,
            currentLocation
          );

          if (!eligibility) {
            dispatch(CheckInDispatchEvents.SET_IS_TOO_FAR);
            dispatch(CheckInDispatchEvents.SET_LOADING, false);

            await checkInToEvent({
              sessionId: sessionId,
              user: {
                id: loggedInUser?.id,
                walletAddress: loggedInUser?.embedded_wallet_id,
              },
              deviceInfo: "",
              browserInfo: "",
              location: currentLocation,
              checkinOutcome: CheckInOutcome.FAILED,
              failedReason: "User is too far from the venue",
              event: currentEvent,
            });

            return;
          }

          // To get the check in number for increment purposes
          const latestCheckInHistory = await getCheckInLatestHistory(
            currentEvent.id ?? currentEvent.event_id
          );

          // Get the check in number
          const checkInNumber = latestCheckInHistory?.checkin_number
            ? latestCheckInHistory.checkin_number + 1
            : 1;

          // Get the official check in number if user is logged in
          const officialCheckinNumber = loggedInUser
            ? latestCheckInHistory?.official_checkin_number
              ? latestCheckInHistory.official_checkin_number + 1
              : 1
            : null;

          const checkInResult = await checkInToEvent({
            sessionId: sessionId, // sessionId
            user: {
              id: loggedInUser?.id,
              walletAddress: loggedInUser?.embedded_wallet_id,
            },
            deviceInfo: "",
            browserInfo: "",
            location: currentLocation,
            checkinOutcome: CheckInOutcome.SUCCESS,
            failedReason: "",
            checkinNumber: checkInNumber,
            event: currentEvent,
            officialCheckinNumber,
          });

          if (checkInResult?.success) {
            dispatch(CheckInDispatchEvents.SUCCESSFULLY_CHECKED_IN);
            dispatch(
              CheckInDispatchEvents.SET_CURRENT_CHECK_IN,
              checkInResult.data
            );
          } else
            return dispatch(
              CheckInDispatchEvents.SET_ERRORS,
              "There seems to be an error happening when checking in, apologies. Try again later."
            );

          const performances = await getEventPerformances(
            currentEvent.id ?? currentEvent.event_id,
            currentEvent?.date ?? currentEvent?.event_date
          );
          if (!performances?.success || performances?.data.length < 1) {
            return dispatch(
              CheckInDispatchEvents.SET_ERRORS,
              "There seems to be an error happening when claiming your moment, apologies. Try again later."
            );
          }

          dispatch(CheckInDispatchEvents.SET_PERFORMANCES, performances?.data);

          if (performances?.data.length === 1) {
            const performance = performances?.data[0];

            if (!loggedInUser) {
              await setDelay(2000);

              setSelectedPerformances([performance]);
              return setMomentNeedsLogin();
            }

            const moment = await handleMomentAdd(
              checkInResult.data.id,
              currentEvent.id ?? currentEvent.event_id,
              performance.artist,
              performance.artist_id,
              performance.performance_id,
              officialCheckinNumber,
              currentEvent?.combined_artist_ids
            );

            if (!moment?.success || !moment.data)
              return dispatch(
                CheckInDispatchEvents.SET_ERRORS,
                "There seems to be an error happening when claiming your moment, apologies. Try again later."
              );

            queryClient.removeQueries({
              queryKey: ["profile", loggedInUser.id.toString()],
            });

            const checkedUserInterested = await checkUserInterestByUserId(
              moment?.data?.event_id,
              loggedInUser?.id
            );
            const actionResult = await setUserAction({
              action_type: "event_checkin",
              user_id: loggedInUser?.id,
              target_type: "event",
              target_id: moment?.data?.event_id,
              hasInterested: checkedUserInterested?.length >= 1 ? true : false,
              performersClaimed: 1,
              artist_ids: [performance?.artist_id],
            });
            if (actionResult?.success) {
              const userXp = await getUserXp(loggedInUser?.id);
              setEarnedXp(actionResult?.data?.xp?.xp, userXp?.data?.total_xp);
            }

            setMomentsCollected([moment?.data]);
            setSuccessfullyClaimedMoment();
            await handleBadgesAttained();

            await handlePostEventRoomCreation(moment?.data);

            dispatch(CheckInDispatchEvents.SET_LOADING, false);
            return;
          }

          if (performances?.data.length > 1) {
            dispatch(CheckInDispatchEvents.SET_SELECTING_MOMENTS, true);
            return;
          }
        } catch (error) {
          console.error("error", error);
          dispatch(
            CheckInDispatchEvents.SET_ERRORS,
            "There seems to be an error happening when checking in, apologies. Try again later."
          );
        }
      },
      (error) => {
        console.error("error", error);
      },
      options
    );
  };

  const handleBlindCheckinEvent = async (_currentEvent) => {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition(
      async (currentLocation) => {
        try {
          dispatch(CheckInDispatchEvents.SET_RESET_EXCEPT_CURRENT_EVENT);
          dispatch(CheckInDispatchEvents.SET_LOADING, true);

          await setDelay(6000);

          // // Validates if user has moment to the current event already
          const hasMoment = await getUserHasMomentAlready(_currentEvent);

          if (hasMoment) return;

          // If user is logged out, validate if logged out user has duplicate check in
          if (!loggedInUser) {
            const duplicate = await getCheckInDuplicate(
              _currentEvent,
              currentLocation
            );

            if (duplicate)
              return dispatch(CheckInDispatchEvents.SET_CHECK_IN_ALREADY);
          }

          if (!_currentEvent) {
            dispatch(
              CheckInDispatchEvents.SET_ERRORS,
              "There seems to be an error happening when checking in, apologies. Try again later."
            );
            return;
          }

          // // Get the eligibility status of the event if user moved places
          const eligibility = await IsEventStillNearby(
            _currentEvent,
            currentLocation
          );

          if (!eligibility) {
            dispatch(CheckInDispatchEvents.SET_IS_TOO_FAR);
            dispatch(CheckInDispatchEvents.SET_LOADING, false);

            await checkInToEvent({
              sessionId: sessionId,
              user: {
                id: loggedInUser?.id,
                walletAddress: loggedInUser?.embedded_wallet_id,
              },
              deviceInfo: "",
              browserInfo: "",
              location: currentLocation,
              checkinOutcome: CheckInOutcome.FAILED,
              failedReason: "User is too far from the venue",
              event: currentEvent,
            }).catch((error) => {
              console.error("error", error);
            });

            return;
          }

          // To get the check in number for increment purposes
          const latestCheckInHistory = await getCheckInLatestHistory(
            currentEvent.id ?? currentEvent.event_id
          );

          // Get the check in number
          const checkInNumber = latestCheckInHistory?.checkin_number
            ? latestCheckInHistory.checkin_number + 1
            : 1;

          // Get the official check in number if user is logged in
          const officialCheckinNumber = loggedInUser
            ? latestCheckInHistory?.official_checkin_number
              ? latestCheckInHistory.official_checkin_number + 1
              : 1
            : null;

          const checkInResult = await checkInToEvent({
            sessionId: sessionId, // sessionId
            user: {
              id: loggedInUser?.id,
              walletAddress: loggedInUser?.embedded_wallet_id,
            },
            deviceInfo: "",
            browserInfo: "",
            location: currentLocation,
            checkinOutcome: CheckInOutcome.SUCCESS,
            failedReason: "",
            checkinNumber: checkInNumber,
            event: _currentEvent,
            officialCheckinNumber,
          });

          if (checkInResult?.success) {
            dispatch(CheckInDispatchEvents.SUCCESSFULLY_CHECKED_IN);
            dispatch(
              CheckInDispatchEvents.SET_CURRENT_CHECK_IN,
              checkInResult.data
            );
          } else
            return dispatch(
              CheckInDispatchEvents.SET_ERRORS,
              "There seems to be an error happening when checking in, apologies. Try again later."
            );

          const performances = await getEventPerformances(
            _currentEvent.id ?? _currentEvent.event_id,
            _currentEvent.date ?? _currentEvent.event_date
          );

          if (!performances?.success || performances?.data.length < 1) {
            return dispatch(
              CheckInDispatchEvents.SET_ERRORS,
              "There seems to be an error happening when claiming your moment, apologies. Try again later."
            );
          }

          dispatch(CheckInDispatchEvents.SET_PERFORMANCES, performances?.data);

          if (performances?.data.length === 1) {
            const performance = performances?.data[0];

            if (!loggedInUser) {
              await setDelay(2000);

              setSelectedPerformances([performance]);
              return setMomentNeedsLogin();
            }

            const moment = await handleMomentAdd(
              checkInResult.data.id,
              _currentEvent.id ?? _currentEvent.event_id,
              performance.artist,
              performance.artist_id,
              performance.performance_id,
              officialCheckinNumber,
              _currentEvent?.combined_artist_ids
            );

            if (!moment?.success || !moment.data)
              return dispatch(
                CheckInDispatchEvents.SET_ERRORS,
                "There seems to be an error happening when claiming your moment, apologies. Try again later."
              );

            const checkedUserInterested = await checkUserInterestByUserId(
              moment?.data?.event_id,
              loggedInUser?.id
            );
            const actionResult = await setUserAction({
              action_type: "event_checkin",
              user_id: loggedInUser?.id,
              target_type: "event",
              target_id: moment?.data?.event_id,
              hasInterested: checkedUserInterested?.length >= 1 ? true : false,
              performersClaimed: 1,
              artist_ids: [performance?.artist_id],
            });
            if (actionResult?.success) {
              const userXp = await getUserXp(loggedInUser?.id);
              setEarnedXp(actionResult?.data?.xp?.xp, userXp?.data?.total_xp);
            }

            setMomentsCollected([moment?.data]);
            setSuccessfullyClaimedMoment();
            await handleBadgesAttained();

            await handlePostEventRoomCreation(moment?.data);

            queryClient.removeQueries({
              queryKey: ["profile", loggedInUser.id.toString()],
            });

            dispatch(CheckInDispatchEvents.SET_LOADING, false);
            return;
          }

          if (performances?.data.length > 1) {
            dispatch(CheckInDispatchEvents.SET_SELECTING_MOMENTS, true);
            return;
          }
        } catch (error) {
          console.error("error", error);
          dispatch(
            CheckInDispatchEvents.SET_ERRORS,
            "There seems to be an error happening when checking in, apologies. Try again later."
          );
        }
      },
      (error) => {
        console.error("error", error);
      },
      options
    );
  };

  return {
    currentEvent,
    currentCheckIn,
    error,
    AiImage,
    isCheckedIn,
    isEligible,
    isLoading,
    hasMomentExisted,
    isNearby,
    isTooFar,
    successfullyCheckedIn,
    successfullyClaimedMoment,
    successfullyEarnedXp,
    momentNeedsLogin,
    momentsCollected,
    selectingMoments,
    selectedPerformances,
    performances,
    earnedXp,
    setCurrentEvent,
    setMomentNeedsLogin,
    handleMomentAdd,
    handleBadgesAttained,
    setSelectedPerformances,
    setMomentsCollected,
    setSuccessfullyClaimedMoment,
    setSuccessfullyEarnedXp,
    setEarnedXp,
    handlePostEventRoomCreation,
    handleCheckInCurrentEvent,
    handleBlindCheckinEvent,
  };
}

export default useCheckIn;
