import { Link, useNavigate, useParams } from "react-router-dom";
import TopNav from "../../components/TopNav";
import {
  AvatarPlaceholder,
  GlassmorphPlaceholder,
  NavBarTypes,
} from "../../constants/constants";

import {
  BodyText,
  ButtonRectXs,
  FooterText,
  FooterTextSm,
  HeaderXl,
  IconWithText,
  Section,
  Title,
} from "../../GlobalStyles";
import ArtistIcon from "../../components/Artist/ArtistIcon";
import BaseIcon from "../../assets/svg/base.svg?react";
import PosterIcon from "../../assets/svg/poster-icon.svg?react";
import PhotoIcon from "../../assets/svg/photo.svg?react";
import AirdropIcon from "../../assets/svg/airdrop-icon.svg?react";
import EditionIcon from "../../assets/svg/edition-icon.svg?react";
import OpenseaIcon from "../../assets/svg/opensea.svg?react";
import MagicEdenIcon from "../../assets/svg/magic-eden.svg?react";
import USDCIcon from "../../assets/svg/usdc.svg?react";
import { theme } from "../../utils/theme";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style";
import ErrorPage from "../../components/ErrorPage";
import moment from "moment";
import CollectibleDescriptionItem from "../../components/Collectibles/CollectibleDescriptionItem";
import {
  ArtistContainer,
  ChainIconContainer,
  Container,
  DescriptionItemsContainer,
  HeaderContainer,
  Image,
  ImageContainer,
  UserIcon,
} from "../Collectible/Collectible-Styles";
import { useExternalCollectibleQuery } from "../Collectible/collectiblesQueries";

const { colors } = theme;

export default function ExternalCollectible() {
  const { profileId, collectibleId, tokenId } = useParams();

  const { data, isLoading, error } = useExternalCollectibleQuery({
    userId: profileId,
    collectibleId,
    tokenId,
  });
  const navigate = useNavigate();
  console.log(data);
  if (isLoading)
    return (
      <Section>
        <TopNav type={NavBarTypes.COLLECTIBLES} />
        <Container style={{ padding: "0 20px 16px" }}>
          <HeaderContainer>
            <SkeletonElement height="24px" width="100px" />
            <HeaderContainer style={{ gap: "12px" }}>
              <SkeletonElement height="24px" width="80px" />
              <SkeletonElement height="24px" width="80px" />
            </HeaderContainer>
          </HeaderContainer>
          <SkeletonElement height="350px" width="100%" radius="8px" />

          <HeaderContainer>
            <IconWithText>
              <SkeletonElement height="17px" width="80px" />
              <SkeletonElement height="17px" width="100px" />
            </IconWithText>
            <SkeletonElement height="17px" width="100px" />
          </HeaderContainer>
          <Container style={{ gap: "8px" }}>
            <SkeletonElement height="14px" width="100px" />
            <SkeletonElement height="14px" width="100%" />
            <SkeletonElement height="14px" width="100%" />
            <SkeletonElement height="14px" width="50%" />
          </Container>
          <Container style={{ gap: "12px" }}>
            <SkeletonElement height="20px" width="200px" />

            <DescriptionItemsContainer>
              {Array.from({ length: 6 }).map((_, index) => (
                <SkeletonElement key={index} height="63px" width="100%" />
              ))}
            </DescriptionItemsContainer>
          </Container>
        </Container>
      </Section>
    );

  if (error) return <ErrorPage />;

  return (
    <Section>
      <TopNav type={NavBarTypes.COLLECTIBLES} />
      <Container style={{ padding: "0 20px 16px" }}>
        <HeaderContainer>
          <Title>#{tokenId}</Title>
          <HeaderContainer style={{ gap: "12px" }}>
            <IconWithText gap="8px">
              <PhotoIcon stroke={colors.grey} height="17px" width="17px" />

              <FooterText
                color={colors.grey}
                style={{ textTransform: "capitalize" }}
              >
                Photo
              </FooterText>
            </IconWithText>
          </HeaderContainer>
        </HeaderContainer>
        <ImageContainer>
          {data.image && (
            <Image
              src={data.image}
              alt="collectible"
              onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            />
          )}

          <ChainIconContainer></ChainIconContainer>
          <BaseIcon
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
            }}
          />
        </ImageContainer>
        <HeaderXl style={{ textTransform: "uppercase" }} truncate>
          {data.name}
        </HeaderXl>
        <HeaderContainer>
          <IconWithText onClick={() => navigate(`/profile/${profileId}`)}>
            <BodyText weight="600">Owned by</BodyText>
            <IconWithText gap="8px">
              <UserIcon
                src={data.avatar || AvatarPlaceholder}
                onError={(e) => (e.target.src = AvatarPlaceholder)}
              />
              <FooterText>{data.username}</FooterText>
            </IconWithText>
          </IconWithText>
          <BodyText color={colors.green}>
            {moment(data.created_at).format("DD MMM YYYY")}
          </BodyText>
        </HeaderContainer>
        <Container style={{ gap: "8px" }}>
          <BodyText weight="600">Description</BodyText>
          <BodyText color={colors.grey}>
            {data.description || "No description"}
          </BodyText>
        </Container>
        <Container style={{ gap: "12px" }}>
          <BodyText weight="600">Trade your collectible</BodyText>
        </Container>
        <DescriptionItemsContainer>
          <CollectibleDescriptionItem
            label="Price"
            value="N/A"
            icon={<USDCIcon height={16} width={16} />}
          />

          <CollectibleDescriptionItem
            label="Edition"
            value="ERC-1155"
            icon={<EditionIcon />}
          />

          {(typeof royalty === "number" || typeof royalty === "string") && (
            <CollectibleDescriptionItem
              label="Creator earnings"
              value="Zora Based"
            />
          )}
          {/* TODO: Get from table*/}
          <CollectibleDescriptionItem label="Total supply" value={1000} />

          <CollectibleDescriptionItem
            label="Contract address"
            value={`${data.contract_address.slice(0, 4)}...${data.contract_address.slice(-4)}`}
          />
        </DescriptionItemsContainer>

        <ButtonRectXs
          style={{ width: "fit-content", alignSelf: "center" }}
          as={Link}
          to={`https://basescan.org/tx/${data.transaction_hash}`}
          target="_blank"
        >
          <FooterTextSm weight="600">See transaction</FooterTextSm>
        </ButtonRectXs>
      </Container>
    </Section>
  );
}
