import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  padding: 0 20px;
`;

export const CollectiblesTabContainer = styled.div`
  display: grid;
  gap: 16px 16px;
  place-content: center;
  overflow-x: hidden;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(6, minmax(0, 180px));
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(5, minmax(0, 180px));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(3, minmax(0, 180px));
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(2, minmax(0, 180px));
  }

  @media (max-width: 315px) {
    grid-template-columns: repeat(2, minmax(0, 180px));
  }
`;
