import useGlobalModal from "../../hooks/useGlobalModal";
import PropTypes from "prop-types";
import Sheet from "react-modal-sheet";
import { theme } from "../../utils/theme";
import useMomentPhotoUpload from "../../hooks/useMomentPhotoUpload";
import { useCheckoutStore } from "../Checkout/checkoutStore";
import { useBuyTicketStore } from "../BuyTicket/buyTicketStore";

const defaltStyle = {
  container: {
    background: theme.colors.background,
    borderRadius: "20px 20px 0 0",
    height: "fit-content !important",
    maxHeight: "100% !important",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "12px",
  },
};

export default function SheetModal() {
  const {
    isSheetModalOpen,
    sheetModalContent,
    sheetBackdropStyle,
    toggleSheetModal,
    setSheetModalContent,
    setSheetModalBackdropStyle,
  } = useGlobalModal();
  const { loading } = useMomentPhotoUpload();
  const {
    setPaymentMethod,
    setCardPaymentMethod,
    boughtTickets,
    reset: resetCheckout,
  } = useCheckoutStore();
  const { reset: resetBuyTicket } = useBuyTicketStore();

  const closeModal = () => {
    if (loading) return;

    if (boughtTickets?.length > 0) {
      toggleSheetModal();
      setSheetModalContent(null);

      resetBuyTicket();
      resetCheckout();
      return;
    }

    setSheetModalBackdropStyle({});
    setSheetModalContent(null);
    toggleSheetModal();
    setPaymentMethod(null);
    setCardPaymentMethod(null);
  };

  const mergedStyle = {
    sheet: {
      ...defaltStyle.sheet,
    },
    container: {
      ...defaltStyle.container,
    },
    content: {
      ...defaltStyle.content,
    },
    backdrop: {
      ...defaltStyle.backdrop,
      ...sheetBackdropStyle,
    },
  };

  return (
    <Sheet
      isOpen={isSheetModalOpen}
      onClose={closeModal}
      detent="content-height"
    >
      <Sheet.Container style={mergedStyle.container}>
        <Sheet.Header />
        <Sheet.Content>
          <Sheet.Scroller draggableAt="both" style={mergedStyle.content}>
            {sheetModalContent}
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeModal} style={mergedStyle.backdrop} />
    </Sheet>
  );
}

SheetModal.propTypes = {
  modalProps: PropTypes.object,
};
