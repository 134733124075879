import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetTickets } from "./ticketQueries";
import {
  BodyText,
  ButtonRectSm,
  FooterText,
  IconWithText,
  Section,
  Title,
} from "../../GlobalStyles";
import TopNav from "../../components/TopNav";
import {
  ArtistContainer,
  ChainIconContainer,
  Container,
  DescriptionItemsContainer,
  HeaderContainer,
  Image,
  ImageContainer,
  UserIcon,
} from "../Collectible/Collectible-Styles";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style";
import {
  AvatarPlaceholder,
  GlassmorphPlaceholder,
  NavBarTypes,
} from "../../constants/constants";
import ArtistIcon from "../../components/Artist/ArtistIcon";
import BaseIcon from "../../assets/svg/base.svg?react";
import TwoTicketsIcon from "../../assets/svg/two-tickets.svg?react";
import MapIcon from "../../assets/svg/map.svg?react";
import BookmarkIcon from "../../assets/svg/bookmark.svg?react";
import PlaneIcon from "../../assets/svg/plane.svg?react";
import EditionIcon from "../../assets/svg/edition-icon.svg?react";
import OpenseaIcon from "../../assets/svg/opensea.svg?react";
import MagicEdenIcon from "../../assets/svg/magic-eden.svg?react";
import USDCIcon from "../../assets/svg/usdc.svg?react";
import { theme } from "../../utils/theme";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import CollectibleDescriptionItem from "../../components/Collectibles/CollectibleDescriptionItem";
import { formatPrice } from "../../utils/utils";
import { ArrowUpRight } from "@phosphor-icons/react";
import { TokenIdsContainer } from "./Ticket-Styles";
import { DescriptionItem } from "../../components/Collectibles/CollectibleDescriptionItem/CollectibleDescriptionItem-Styles";
import ErrorPage from "../Error";

const { colors } = theme;

export default function Ticket() {
  const { profileId, eventId, ticketType } = useParams();
  const { data, isLoading } = useGetTickets({
    userId: profileId,
    eventId,
    type: ticketType,
  });
  const navigate = useNavigate();
  // Set initial state for showing all tickets
  const [showAllTickets, setShowAllTickets] = useState(false);

  // Display all events if showAllEvents is true, otherwise display the first 3 events
  const [displayedTickets, setDisplayedTickets] = useState();

  const {
    type,
    created_at,
    price,
    user_id,
    username,
    avatar,
    headline_artist_id,
    artists,
    venue,
    city,
    country,
    image,
    date,
    contract_address,
    contract_data,
  } = data?.[0] || {};

  const {
    symbol,
    royaltyPercentage,
    maxResalePriceVIP,
    maxResalePriceGA,
    maxSupplyVIP,
    maxSupplyGA,
    eden_url,
    opensea_url,
  } = contract_data || {};

  const headlineArtist = useMemo(
    () => artists?.find((artist) => artist.id === headline_artist_id),
    [artists, headline_artist_id]
  );

  const { id: artist_id, artist_name, profile_img } = headlineArtist || {};

  const handleDisplayedTickets = () => {
    setShowAllTickets(!showAllTickets);

    if (!showAllTickets) {
      setDisplayedTickets(data);
    } else {
      setDisplayedTickets(data?.slice(0, 3));
    }
  };

  // Update the displayed tickets when the data is fetched
  useEffect(() => {
    if (!data || data.length === 0 || isLoading) return;

    setDisplayedTickets(data?.slice(0, 3));
  }, [data]);

  if (isLoading)
    return (
      <Section>
        <TopNav type={NavBarTypes.TICKET} />
        <Container style={{ padding: "0 20px 16px" }}>
          <HeaderContainer>
            <SkeletonElement height="24px" width="100px" />
            <HeaderContainer style={{ gap: "12px" }}>
              <SkeletonElement height="24px" width="80px" />
              <SkeletonElement height="24px" width="80px" />
            </HeaderContainer>
          </HeaderContainer>
          <SkeletonElement height="350px" width="100%" radius="8px" />
          <SkeletonElement height="38px" width="250px" />
          <ArtistContainer>
            <SkeletonElement height="40px" width="40px" radius="50%" />
            <SkeletonElement height="24px" width="100px" />
          </ArtistContainer>
          <HeaderContainer>
            <IconWithText>
              <SkeletonElement height="17px" width="80px" />
              <SkeletonElement height="17px" width="100px" />
            </IconWithText>
            <SkeletonElement height="17px" width="100px" />
          </HeaderContainer>
          <Container style={{ gap: "8px" }}>
            <SkeletonElement height="14px" width="100px" />
            <SkeletonElement height="14px" width="100%" />
            <SkeletonElement height="14px" width="100%" />
            <SkeletonElement height="14px" width="50%" />
          </Container>
          <Container style={{ gap: "12px" }}>
            <SkeletonElement height="20px" width="200px" />
            <DescriptionItemsContainer>
              <SkeletonElement height="27px" width="100%" />
              <SkeletonElement height="27px" width="100%" />
            </DescriptionItemsContainer>
            <DescriptionItemsContainer>
              {Array.from({ length: 6 }).map((_, index) => (
                <SkeletonElement key={index} height="63px" width="100%" />
              ))}
            </DescriptionItemsContainer>
            <SkeletonElement
              height="22px"
              width="150px"
              style={{
                alignSelf: "center",
              }}
            />
          </Container>
        </Container>
      </Section>
    );

  if (data?.length === 0 || !data) {
    return <ErrorPage description={"Can't seem to find this ticket."} />;
  }

  return (
    <Section>
      <TopNav type={NavBarTypes.TICKET} />
      <Container style={{ padding: "0 20px 16px" }}>
        <HeaderContainer>
          <Title>{type}</Title>
          <HeaderContainer style={{ gap: "12px" }}>
            <IconWithText gap="8px">
              <TwoTicketsIcon height={12} width={12} stroke={colors.grey} />
              <FooterText
                color={colors.grey}
                style={{ textTransform: "capitalize" }}
              >
                Ticket
              </FooterText>
            </IconWithText>
            <BodyText weight="600">${formatPrice(price, false)}</BodyText>
          </HeaderContainer>
        </HeaderContainer>
        <ImageContainer>
          {image && (
            <Image
              src={image}
              alt="collectible"
              onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            />
          )}
          <ChainIconContainer>
            <BaseIcon />
          </ChainIconContainer>
        </ImageContainer>
        <Container style={{ gap: "12px" }}>
          <HeaderContainer>
            <ArtistContainer onClick={() => navigate(`/artists/${artist_id}`)}>
              <ArtistIcon imageUrl={profile_img} />
              <BodyText truncate>{artist_name}</BodyText>
            </ArtistContainer>
            <ButtonRectSm
              primary
              onClick={() =>
                navigate(`/events/${eventId}?date=${date.split("+")[0]}`)
              }
            >
              <FooterText>View event</FooterText>
              <ArrowUpRight size={20} />
            </ButtonRectSm>
          </HeaderContainer>
          <Container style={{ gap: "8px" }}>
            <IconWithText>
              <div>
                <MapIcon
                  height={12}
                  width={12}
                  stroke={colors.white}
                  fill={colors.white}
                />
              </div>
              <BodyText truncate>
                {venue}, {city}, {country}
              </BodyText>
            </IconWithText>
            <IconWithText>
              <div>
                <BookmarkIcon
                  height={12}
                  width={12}
                  stroke={colors.white}
                  fill={colors.white}
                />
              </div>
              <BodyText truncate color={colors.green}>
                {moment(date).utc().format("ddd DD MMM")}
              </BodyText>
              <FooterText color={colors.grey}>
                {moment(date).utc().format("hh:ss A")}
              </FooterText>
            </IconWithText>
          </Container>
        </Container>
        <HeaderContainer style={{ margin: "8px 0" }}>
          <IconWithText onClick={() => navigate(`/profile/${user_id}`)}>
            <BodyText weight="600">Owned by</BodyText>
            <IconWithText gap="8px">
              <UserIcon
                src={avatar || AvatarPlaceholder}
                onError={(e) => (e.target.src = AvatarPlaceholder)}
              />
              <FooterText>{username}</FooterText>
            </IconWithText>
          </IconWithText>
          <BodyText color={colors.green}>
            {moment(created_at).format("DD MMM YYYY")}
          </BodyText>
        </HeaderContainer>
        <TokenIdsContainer>
          <BodyText weight="600">Tickets</BodyText>
          {displayedTickets?.map(({ token_id }) => (
            <DescriptionItem
              key={token_id}
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <BodyText weight="600">#{token_id}</BodyText>
              <ButtonRectSm>
                <PlaneIcon stroke={colors.white} />
                <FooterText weight="600">Transfer</FooterText>
              </ButtonRectSm>
            </DescriptionItem>
          ))}
          {data?.length > 3 && (
            <FooterText
              color={colors.grey}
              onClick={handleDisplayedTickets}
              style={{
                cursor: "pointer",
                alignSelf: "flex-end",
              }}
            >
              {showAllTickets ? "Show less" : "See more"}
            </FooterText>
          )}
        </TokenIdsContainer>
        {(eden_url || opensea_url) && (
          <Container style={{ gap: "12px" }}>
            <BodyText weight="600">Trade your collectible</BodyText>
            <DescriptionItemsContainer style={{ margin: 0 }}>
              {eden_url && (
                <IconWithText
                  style={{ gap: "8px" }}
                  as={eden_url ? Link : "div"}
                  to={eden_url}
                  target="_blank"
                >
                  <MagicEdenIcon />
                  <FooterText>Magic Eden</FooterText>
                </IconWithText>
              )}
              {opensea_url && (
                <IconWithText
                  style={{ gap: "8px" }}
                  as={opensea_url ? Link : "div"}
                  to={opensea_url}
                  target="_blank"
                >
                  <OpenseaIcon />
                  <FooterText>Opensea</FooterText>
                </IconWithText>
              )}
            </DescriptionItemsContainer>
          </Container>
        )}
        <DescriptionItemsContainer>
          <CollectibleDescriptionItem
            label="Max resale price"
            value={`$ ${ticketType === "VIP" ? maxResalePriceVIP : maxResalePriceGA}`}
            icon={<USDCIcon height={16} width={16} />}
          />
          <CollectibleDescriptionItem
            label="Edition"
            value="ERC-721"
            icon={<EditionIcon />}
          />
          {royaltyPercentage && (
            <CollectibleDescriptionItem
              label="Creator earnings"
              value={`${royaltyPercentage}%`}
            />
          )}
          {(maxSupplyGA || maxSupplyVIP) && (
            <CollectibleDescriptionItem
              label="Total supply"
              value={(ticketType === "VIP" ? maxSupplyVIP : maxSupplyGA) ?? 0}
            />
          )}
          {contract_address && (
            <CollectibleDescriptionItem
              label="Contract address"
              value={`${contract_address.slice(0, 4)}...${contract_address.slice(-4)}`}
            />
          )}
          {symbol && (
            <CollectibleDescriptionItem
              label="Contract symbol"
              value={symbol}
            />
          )}
        </DescriptionItemsContainer>
      </Container>
    </Section>
  );
}
