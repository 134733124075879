import PropTypes from "prop-types";
import { BodyText, HeaderL } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import {
  Container,
  Content,
  Header,
  ModalButton,
} from "./ClaimRewardModal-Styles";
import { updateExternalMint } from "../../../api/collectibles";
import { useRewardXP } from "./hooks/claimRewardQueries";

const { colors } = theme;

export default function ClaimRewardModal({ externalMint }) {
  const { toggleSheetModal } = useGlobalModal();
  const { mutateAsync: rewardXP } = useRewardXP();
  const handleOnClick = async () => {
    await updateExternalMint(externalMint.transaction_hash);
    rewardXP({
      loggedInUserId: externalMint.user_id,
      action_type: externalMint.transaction_hash,
      xp: externalMint.xp,
    });
    toggleSheetModal();
  };

  return (
    <Container>
      <Header>
        <HeaderL style={{ textTransform: "uppercase" }}>
          congratulations on being a first fan!
        </HeaderL>
      </Header>
      <Content>
        <BodyText color={colors.grey} style={{ marginTop: -12 }}>
          We’re happy that you’re now part of the live music community.
        </BodyText>
        <BodyText
          color={colors.grey}
          style={{ marginLeft: -20, marginRight: -20, marginTop: -12 }}
        >
          We hope to{" "}
          <BodyText color={colors.green}>make more music memories</BodyText>{" "}
          with you!
        </BodyText>
        <ModalButton onClick={handleOnClick}>
          <BodyText color={colors.black} weight="600">
            Claim Reward
          </BodyText>
        </ModalButton>
      </Content>
    </Container>
  );
}
