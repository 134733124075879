import { useMemo } from "react";
import { BodyText, ButtonRect, HeaderL } from "../../../GlobalStyles";
import Checkbox from "../../../assets/svg/checkbox.svg?react";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useCheckoutStore } from "../checkoutStore";
import {
  Container,
  HeaderContainer,
  TicketsContainer,
} from "./PaymentSuccess-Styles";
import TicketItem from "../../Tickets/TicketItem";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";

export default function PaymentSuccess() {
  const { event, reset: resetBuyTicket, contract } = useBuyTicketStore();
  const { boughtTickets, reset: resetCheckout } = useCheckoutStore();
  const { toggleSheetModal } = useGlobalModal();
  const { headline_artist_id, artists, venue, city, country, date } =
    event || {};
  const { image } = contract || {};

  const ticketsCount = useMemo(() => {
    if (!boughtTickets) return 0;

    return boughtTickets.reduce((total, ticket) => total + ticket.count, 0);
  }, [boughtTickets]);

  const handleConfirm = () => {
    toggleSheetModal();

    resetBuyTicket();
    resetCheckout();
  };

  return (
    <Container>
      <HeaderContainer>
        <Checkbox stroke={theme.colors.green} />
        <HeaderL
          style={{ textTransform: "uppercase", margin: "16px 0 12px 0" }}
        >
          Congratulations!
        </HeaderL>
        <BodyText secondary>
          You successfully bought {ticketsCount}{" "}
          {ticketsCount <= 1 ? "ticket" : "tickets"} and supported the artist
          directly.
        </BodyText>
      </HeaderContainer>
      {boughtTickets?.length > 0 && (
        <TicketsContainer>
          {boughtTickets.map((ticket, index) => {
            const ticketData = {
              ...ticket,
              headline_artist_id,
              artists,
              image,
              venue,
              city,
              country,
              date,
            };
            return <TicketItem key={index} ticket={ticketData} />;
          })}
        </TicketsContainer>
      )}
      <ButtonRect primary onClick={handleConfirm} style={{ marginTop: 8 }}>
        <BodyText weight="600">Confirm</BodyText>
      </ButtonRect>
    </Container>
  );
}
