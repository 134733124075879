import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import MomentView from "../../components/Moments/MomentView";
import useMomentPhotoUpload from "../../hooks/useMomentPhotoUpload";
import { useQuery } from "@tanstack/react-query";
import { momentLoader } from "./loader";
import ErrorPage from "../Error";
import { MomentSkeleton } from "../../components/Moments/MomentSkeleton";
import { useAlchemyQuery } from "../Profile/hooks/profileQueries";

export default function Moment() {
  const { momentId, profileId } = useParams();
  const { loggedInUser } = useAuth();

  const { isLoading, data, error } = useQuery({
    queryKey: ["moment", momentId],
    queryFn: () =>
      momentLoader({
        momentId,
        ownerId: profileId,
        loggedInUserId: loggedInUser?.id,
      }),
  });

  const isOwner = loggedInUser?.id == profileId;

  useAlchemyQuery();

  const { setMoment: setUploadMoment, setReset } = useMomentPhotoUpload();

  useEffect(() => {
    setReset();

    if (!data || isLoading || !loggedInUser || !isOwner) return;

    setUploadMoment(data);
  }, [loggedInUser, data, isLoading]);

  if (isLoading) return <MomentSkeleton isOwner={isOwner} />;

  if (error) return <ErrorPage />;

  return <MomentView moment={data} />;
}
