import moment from "moment";
import { BodyText, FooterText, FooterTextSm } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import PosterIcon from "../../../assets/svg/poster-icon.svg?react";
import PhotoIcon from "../../../assets/svg/photo.svg?react";
import AirdropIcon from "../../../assets/svg/airdrop-icon.svg?react";
import EditionIcon from "../../../assets/svg/edition-icon.svg?react";
import {
  Container,
  DetailsContainer,
  HeaderContainer,
  Image,
  ImageContainer,
} from "./CollectibleThumbnail-Styles";
import PropTypes from "prop-types";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import { Link, useLocation } from "react-router-dom";

const { colors } = theme;

export default function CollectibleThumbnail({ collectible, isExternal }) {
  const location = useLocation();
  if (isExternal) {
    const { contract_address, token_id, image, link, name, description } =
      collectible;

    return (
      <Container as={Link} to={`collectibles/${contract_address}/${token_id}`}>
        <HeaderContainer>
          <FooterText weight="600">#{token_id}</FooterText>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <PosterIcon stroke={colors.grey} />

            <EditionIcon stroke={colors.grey} height={14} width={14} />
          </div>
        </HeaderContainer>
        <ImageContainer>
          <Image
            src={image ?? GlassmorphPlaceholder}
            alt="collectible"
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          />
        </ImageContainer>
        <DetailsContainer>
          {/* Sat 7 Dec */}
          {/* <FooterText color={colors.grey} truncate>
            {moment(created_at).format("ddd DD MMM")}
          </FooterText> */}
          <BodyText weight="600" truncate style={{ margin: "4px 0 8px 0" }}>
            {name}
          </BodyText>
          <FooterTextSm color={colors.grey} truncate>
            Momentify Badge
          </FooterTextSm>
        </DetailsContainer>
      </Container>
    );
  }

  const {
    id,
    created_at,
    image,
    artist,
    edition_name,
    edition_type = "airdrop",
    type,
    user_ids,
    user_id,
  } = collectible || {};

  return (
    <Container
      as={Link}
      to={
        location.pathname?.includes("profile")
          ? `collectibles/${id}?type=${edition_type}`
          : `/profile/${user_id ?? user_ids?.[0]}/collectibles/${id}?type=${edition_type}`
      }
    >
      <HeaderContainer>
        <FooterText weight="600">#{id}</FooterText>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {type === "Poster" ? (
            <PosterIcon stroke={colors.grey} />
          ) : (
            <PhotoIcon stroke={colors.grey} height={14} width={14} />
          )}
          {edition_type === "airdrop" ? (
            <AirdropIcon stroke={colors.grey} />
          ) : (
            <EditionIcon stroke={colors.grey} height={14} width={14} />
          )}
        </div>
      </HeaderContainer>
      <ImageContainer>
        <Image
          src={image ?? GlassmorphPlaceholder}
          alt="collectible"
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
      </ImageContainer>
      <DetailsContainer>
        {/* Sat 7 Dec */}
        <FooterText color={colors.grey} truncate>
          {moment(created_at).format("ddd DD MMM")}
        </FooterText>
        <BodyText weight="600" truncate style={{ margin: "4px 0 8px 0" }}>
          {artist}
        </BodyText>
        <FooterTextSm color={colors.grey} truncate>
          {edition_name ?? "No edition name"}
        </FooterTextSm>
      </DetailsContainer>
    </Container>
  );
}

CollectibleThumbnail.propTypes = {
  collectible: PropTypes.object,
};
