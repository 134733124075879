import { formatUnits, parseUnits } from "viem";

export const calculateTotal = ({ ticketsTotal, momentifyFee, gas }) => {
  // Convert values to smallest unit (6 decimals for USDC)
  const ticketsTotalInSmallestUnit = parseUnits(ticketsTotal.toString(), 6); // Returns BigInt
  const mintFeeInSmallestUnit = parseUnits(momentifyFee.toString(), 6); // Returns BigInt
  const gasInSmallestUnit = parseUnits(gas.toString(), 6); // Returns BigInt

  // Calculate the total amount required (prices + mint fees + gas)
  const totalAmount =
    ticketsTotalInSmallestUnit + mintFeeInSmallestUnit + gasInSmallestUnit;

  return {
    formattedTotal: formatUnits(totalAmount, 6),
    parsedTotal: totalAmount,
  };
};

export const countTicketsByType = (tickets) => {
  return tickets.reduce(
    (acc, ticket) => {
      const typeKey = ticket.type === "General admission" ? "GA" : ticket.type;
      acc[typeKey] = (acc[typeKey] || 0) + 1;
      return acc;
    },
    { VIP: 0, GA: 0 }
  );
};

export const countAndDeduplicateTickets = (tickets) => {
  // Create a map to store the count for each ticket by its type
  const ticketMap = tickets.reduce((acc, ticket) => {
    const typeKey = ticket.type;

    if (!acc[typeKey]) {
      // Add a new entry with the ticket object and count 1
      acc[typeKey] = { ...ticket, type: typeKey, count: 1 };
    } else {
      // Increment the count if the ticket type already exists
      acc[typeKey].count += 1;
    }

    return acc;
  }, {});

  // Return an array of unique ticket objects with the count added
  return Object.values(ticketMap);
};

export const addDelayInMs = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));
