import PropTypes from "prop-types";
import { useMemo } from "react";
import CheckGreen from "../../../assets/svg/check-green.svg?react";
import { StockImage } from "../../../constants/constants";
import { BodyText, FooterText } from "../../../GlobalStyles";
import { stringToColour } from "../../../utils/utils";
import { SelectedButton } from "../../CheckIn/PerformanceItem";

export const ArtistBox = ({
  artist,
  innerRef,
  setSelectedArtists,
  selectedArtists,
}) => {
  const { artist_name, genre, profile_img, banner_img } = artist;

  const isSelected = useMemo(() => {
    return selectedArtists.some((selected) => selected.id === artist.id);
  }, [selectedArtists, artist]);

  const addToSelected = () => {
    if (isSelected) {
      setSelectedArtists(
        selectedArtists.filter((selected) => selected.id !== artist.id)
      );
    } else if (selectedArtists.length != 3) {
      setSelectedArtists([...selectedArtists, artist]);
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 8,
        minHeight: 180,
        maxHeight: 180,
        maxWidth: 100,
        minWidth: 100,
      }}
      ref={innerRef}
      onClick={addToSelected}
    >
      {/* Image */}
      <div
        style={{
          position: "relative",
        }}
      >
        <img
          style={{
            width: 88,
            height: 88,
            borderRadius: "50%",
            border: "1px solid var(--Button-stroke, #FFFFFF1F)",
          }}
          src={profile_img ?? banner_img ?? StockImage}
        />

        <SelectedButton
          style={{
            position: "absolute",
            top: 8,
            right: 10,
            background: "none",
          }}
          selected={isSelected}
        >
          {isSelected && <CheckGreen />}
        </SelectedButton>
      </div>
      {/* Name */}
      <BodyText
        style={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: 2,
          lineClamp: 2,
        }}
      >
        {artist_name}
      </BodyText>
      {/* Genres */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 8,
          minHeight: 16, // Adjust this value to fit the design requirements
          maxHeight: 40, // Adjust this value to fit the design requirements
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {genre?.map((genre, index) => {
          if (genre !== "Undefined" && index <= 1)
            return (
              <FooterText
                style={{
                  color: stringToColour(genre),
                  opacity: 0.6,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                key={index}
              >
                #{genre}
              </FooterText>
            );
        })}
      </div>
    </div>
  );
};

ArtistBox.propTypes = {
  artist: PropTypes.object,
  innerRef: PropTypes.any,
  selectedArtists: PropTypes.array,
  setSelectedArtists: PropTypes.func,
};
