import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { BodyText, Line } from "../../../GlobalStyles";
import {
  Content,
  LineItem,
  LineLeftItem,
  SummaryHeader,
} from "./OrderSummary-Styles";
import { formatPrice } from "../../../utils/utils";
import AnimatedDropdown from "../../Dropdown/AnimatedDropdown";
import { useCheckoutStore } from "../checkoutStore";

export default function OrderSummary() {
  const { ticketsSelected } = useBuyTicketStore();
  const { momentifyFee, ticketsTotal } = useCheckoutStore();

  const formattedTotal = formatPrice(ticketsTotal + momentifyFee);


  return (
    <AnimatedDropdown
      header={
        <SummaryHeader>
          <LineLeftItem style={{ gap: "10px" }}>
            <BodyText weight="600">Order summary</BodyText>
            <BodyText secondary>{`(${ticketsSelected.length} items)`}</BodyText>
          </LineLeftItem>
          <LineLeftItem style={{ gap: "16px" }}>
            <BodyText>
              ${formattedTotal[0]}.{formattedTotal[1]}
            </BodyText>
          </LineLeftItem>
        </SummaryHeader>
      }
      content={
        <Content>
          <Line style={{ margin: "16px 0 0 0" }} />
          {Object.values(
            ticketsSelected.reduce((acc, ticket) => {
              if (acc[ticket.id]) {
                acc[ticket.id].quantity += 1;
              } else {
                acc[ticket.id] = { ...ticket, quantity: 1 };
              }
              return acc;
            }, {})
          ).map((ticket) => {
            return (
              <LineItem key={ticket.id}>
                <LineLeftItem>
                  <BodyText>{ticket.type} </BodyText>
                  <BodyText secondary> ({ticket.quantity})</BodyText>
                </LineLeftItem>
                <BodyText>${formatPrice(ticket.price * ticket.quantity, false)}</BodyText>
              </LineItem>
            );
          })}
          <LineItem>
            <LineLeftItem>
              <BodyText>Momentify fees</BodyText>
            </LineLeftItem>
            <BodyText>+${formatPrice(momentifyFee, false)}</BodyText>
          </LineItem>
        </Content>
      }
    />
  );
}
