import {
  BodyText,
  ButtonRect,
  FooterText,
  HeaderL,
  HeaderSm,
} from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { Container, FeeContainer } from "./Total-Styles";
import TwoTickets from "../../../assets/svg/two-tickets.svg?react";
import { formatPrice } from "../../../utils/utils";
import useGlobalModal from "../../../hooks/useGlobalModal";
import Checkout from "../../Checkout";
import { useCheckoutStore } from "../../Checkout/checkoutStore";

const { colors } = theme;

export default function Total() {
  const { momentifyFee, ticketsTotal } = useCheckoutStore();
  const { setSheetModalContent, toggleSheetModal, setSheetModalBackdropStyle } =
    useGlobalModal();

  const formattedPrice = formatPrice(ticketsTotal);

  const handleCheckout = () => {
    if (ticketsTotal + momentifyFee <= 0) return;

    setSheetModalContent(<Checkout />);
    setSheetModalBackdropStyle({
      backdropFilter: "blur(10px)",
    });
    toggleSheetModal();
  };

  return (
    <Container>
      <FeeContainer style={{ alignItems: "center", gap: "12px" }}>
        <BodyText weight="600">Total</BodyText>
        <FeeContainer style={{ alignItems: "flex-end", gap: "0px" }}>
          <HeaderL lineHeight="1">${formattedPrice[0]}</HeaderL>
          <HeaderSm>.{formattedPrice[1] ?? "00"}</HeaderSm>
          <FooterText color={colors.grey} style={{ marginLeft: "8px" }}>
            +${formatPrice(momentifyFee, false)} fee
          </FooterText>
        </FeeContainer>
      </FeeContainer>
      <ButtonRect
        background={colors.green}
        style={{ border: "none", padding: "0 16px" }}
        disabled={ticketsTotal <= 0}
        onClick={handleCheckout}
      >
        <TwoTickets stroke={colors.black} height={14} width={14} />
        <FooterText weight="600" color={colors.black}>
          Buy
        </FooterText>
      </ButtonRect>
    </Container>
  );
}
